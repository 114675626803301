import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  InputAdornment,
  TextField,
  Typography
} from "@material-ui/core";
import PropTypes from "prop-types";
import { Formik } from "formik";
import { contractCapital } from "../validations/contract";
import useCapitalManagement from "../hooks/useCapitalManagement";

const CapitalEdit = ({ open, handleClose, data }) => {
  const { edit } = useCapitalManagement();
  // const [textFormality, setTextFormality] = React.useState("1");
  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle
          sx={{ fontSize: 20, textTransform: "uppercase", color: "white" }}
          className="bg-primary"
        >
          Sửa hợp đồng góp vốn
        </DialogTitle>
        <Formik
          initialValues={{
            name: data.name || "",
            amount: data.amount || 0,
            note: data.note || "",
            id: data.id
          }}
          validationSchema={contractCapital()}
          onSubmit={async (values, actions) => {
            await edit(values, actions);
            handleClose();
          }}
        >
          {({
            errors,
            handleChange,
            touched,
            isSubmitting,
            values,
            handleSubmit,
            setFieldValue
          }) => (
            // const selectedTypeLend = typeLend.find(item => item.value === values.period);
            <>
              <DialogContent>
                <Typography
                  color="textPrimary"
                  variant="h4"
                  sx={{ my: 1, textTransform: "uppercase" }}
                >
                  Thông tin góp vốn
                </Typography>
                <Divider />
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                      onChange={handleChange}
                      autoFocus
                      size="small"
                      margin="normal"
                      id="name"
                      value={values.name}
                      label="Tên loại vốn"
                      required
                      type="text"
                      fullWidth
                      variant="outlined"
                    />
                    <TextField
                      error={Boolean(touched.amount && errors.amount)}
                      helperText={touched.amount && errors.amount}
                      onChange={event => {
                        let newValue = event.target.value;
                        newValue =
                          parseInt(newValue.replace(/[^\d\\.]/g, "")) || "";
                        setFieldValue("amount", newValue);
                      }}
                      size="small"
                      margin="normal"
                      id="amount"
                      value={values.amount.toLocaleString("en-US") || 0}
                      label="Số tiền"
                      type="text"
                      fullWidth
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">VNĐ</InputAdornment>
                        )
                      }}
                    />
                    <TextField
                      error={Boolean(touched.note && errors.note)}
                      helperText={touched.note && errors.note}
                      onChange={handleChange}
                      size="small"
                      margin="normal"
                      id="note"
                      value={values.note}
                      label="Ghi chú"
                      type="text"
                      multiline
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions
                sx={{
                  borderTop: "1px solid rgba(224, 224, 224, 1)"
                }}
              >
                <Button onClick={handleClose}>Đóng</Button>
                <Button disabled={isSubmitting} onClick={handleSubmit}>
                  Lưu lại
                </Button>
              </DialogActions>
            </>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};
CapitalEdit.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  data: PropTypes.any
};

export default CapitalEdit;
