import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import MUIDataTable from "mui-datatables";
import { Box } from "@material-ui/core";
import { saveAs } from "file-saver";
import { write, utils } from "xlsx";
import dayjs from "dayjs";
// import SearchIcon from '@mui/icons-material/Search';
// import PrintIcon from '@mui/icons-material/Receipt';
// import DownloadIcon from '@mui/icons-material/GetApp';
// import ViewColumnIcon from '@mui/icons-material/DynamicFeed';
// import FilterIcon from '@mui/icons-material/GroupWork';

const CustomTable = ({ title, data, options, columns, id }) => {
  const [dataList, setDataList] = useState([]);
  const [dataColumn, setDataColumn] = useState([]);

  const defaultOptions = {
    filterType: "dropdown",
    selectableRows: "none",
    rowsPerPageOptions: [10, 25, 50, 100],
    print: false,
    textLabels: {
      body: {
        noMatch: "Xin lỗi, không tìm thấy bản ghi phù hợp",
        toolTip: "Sắp xếp"
      },
      pagination: {
        next: "Trang tiếp theo",
        previous: "Trang trước",
        rowsPerPage: "Hàng trên mỗi trang:",
        displayRows: "của"
        // displayRows: "Hiển thị" // 1-10 of 30
      },
      toolbar: {
        search: "Tìm kiếm",
        viewColumns: "Xem cột",
        filterTable: "Bộ lọc",
        downloadCsv: "Xuất Excel"
      },
      filter: {
        all: "Tất cả",
        title: "Bộ lọc",
        reset: "Đặt lại"
      },
      viewColumns: {
        title: "Hiển thị cột"
      },
      selectedRows: {
        text: "Các dòng đã bị xóa",
        delete: "Xóa"
      }
    }
  };
  const formatValue = (name, value) => {
    if (["amount"].includes(name)) {
      return value.toLocaleString("en-US");
    }
    if (["date", "createdAt"].includes(name)) {
      return dayjs(value).format("DD/MM/YYYY");
    }
    return value;
  };
  const mergeOptions = {
    ...options,
    ...defaultOptions,
    onDownload: (buildHead, buildBody, columnsData, values) => {
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";

      console.log(values.forEach(val => console.log(val)));
      const json = values.reduce((result, val) => {
        const temp = {};
        val.data.forEach((v, idx) => {
          if (columnsData[idx].name !== "id") {
            temp[columnsData[idx].label] = formatValue(
              columnsData[idx].name,
              v
            );
          }
        });
        result.push(temp);
        return result;
      }, []);
      const fileName = id;
      const ws = utils.json_to_sheet(json);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = write(wb, { bookType: "xlsx", type: "array" });
      const dataSave = new Blob([excelBuffer], { type: fileType });
      saveAs(dataSave, fileName + fileExtension);
      // cancel default  CSV download from table
      return false;
    }
  };
  // const components = {
  //   icons: {
  //     SearchIcon,
  //     PrintIcon,
  //     DownloadIcon,
  //     ViewColumnIcon,
  //     FilterIcon,
  //   }
  // };
  useEffect(() => {
    setDataList(data);
  }, [data]);

  useEffect(() => {
    const newDataColumn = columns.map(column => {
      const { hasFilter = true } = column;
      return {
        ...column,
        hasFilter,
        options: {
          ...column.options,
          setCellProps: () => ({
            style: {
              borderRight: "1px solid rgba(224, 224, 224, 1)"
            }
          }),
          setCellHeaderProps: () => ({
            style: {
              borderRight: "1px solid rgba(224, 224, 224, 1)"
            }
          })
        }
      };
    });
    setDataColumn(newDataColumn);
  }, [data]);

  return (
    <Box id="dataTableId">
      <MUIDataTable
        title={title}
        data={dataList}
        options={mergeOptions}
        columns={dataColumn}
        key={id}
        // components={components}
      />
    </Box>
  );
};
CustomTable.propTypes = {
  title: PropTypes.any,
  data: PropTypes.array,
  options: PropTypes.object,
  columns: PropTypes.array,
  id: PropTypes.string
};
export default CustomTable;
