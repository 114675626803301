import { Navigate } from "react-router-dom";
import DashboardLayout from "src/components/DashboardLayout";
import MainLayout from "src/components/MainLayout";
import Account from "src/pages/Account";
import Capital from "src/pages/Capital";
import Dashboard from "src/pages/Dashboard";
import Login from "src/pages/Login";
import NotFound from "src/pages/NotFound";
import ProductList from "src/pages/ProductList";
import Register from "src/pages/Register";
import Settings from "src/pages/Settings";
import AccountList from "./pages/AccountList";
import Procedure from "./pages/Procedure";

const routes = isLoggedIn => [
  {
    path: "app",
    element: isLoggedIn ? (
      <DashboardLayout />
    ) : (
      <Navigate to="/login" replace />
    ),
    children: [
      { path: "accounts", element: <AccountList /> },
      { path: "account", element: <Account /> },
      // { path: "customers", element: <CustomerList /> },
      { path: "dashboard", element: <Dashboard /> },
      { path: "products", element: <ProductList /> },
      // { path: "tin-chap", element: <Procedure /> },
      { path: "gop-von", element: <Capital /> },
      { path: "tra-gop", element: <Procedure /> },
      { path: "settings", element: <Settings /> },
      { path: "*", element: <Navigate to="/404" /> }
    ]
  },
  {
    path: "/",
    element: !isLoggedIn ? <MainLayout /> : <Navigate to="/app/dashboard" />,
    children: [
      { path: "login", element: <Login /> },
      { path: "register", element: <Register /> },
      { path: "404", element: <NotFound /> },
      { path: "/", element: <Navigate to="/app/dashboard" /> },
      { path: "*", element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
