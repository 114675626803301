import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  API_TRANSACTION_PATH,
  getListTransactions
} from "../../services/transaction";

const initialState = {
  list: [],
  listByClient: [],
  contract: {}
};
export const listTransactions = createAsyncThunk(
  `${API_TRANSACTION_PATH}`,
  async contractId => getListTransactions(contractId)
);

export const transactionSlice = createSlice({
  name: "transaction",
  initialState,
  reducers: {
    setTransactionList: (state, { payload }) => {
      state.listTrans = payload;
    }
  },
  extraReducers: {
    [listTransactions.fulfilled]: (state, { payload }) => {
      if (payload.status) {
        const { data } = payload.data;
        state.listTrans = data;
      }
    }
  }
});

export const { setTransactionList } = transactionSlice.actions;

export default transactionSlice.reducer;
