import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  API_PROCEDURE_PATH,
  getAllProcedureCategories
} from "../../services/procedure";

const initialState = {
  list: []
};

export const getProcedureCategoriesList = createAsyncThunk(
  `${API_PROCEDURE_PATH}/categories`,
  async () => {
    const res = await getAllProcedureCategories();
    return res;
  }
);

export const procedureCategorySlice = createSlice({
  name: "procedure-category",
  initialState,
  reducers: {
    setProcedureCategoryList: (state, { payload }) => {
      state.list = payload;
    }
  },
  extraReducers: {
    [getProcedureCategoriesList.fulfilled]: (state, { payload }) => {
      if (payload.status) {
        const { data } = payload.data;
        state.list = data;
      }
    }
  }
});

export const { setProcedureCategoryList } = procedureCategorySlice.actions;

export default procedureCategorySlice.reducer;
