import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import common from "../../constants/common";

import {
  API_PROCEDURE_PATH,
  create,
  edit,
  remove,
  detail,
  getAllProcedures,
  getProceduresByClient,
  getProceduresMasseClient,
  updateMasse,
  removeCategory,
  API_PROCEDURE_CATEGORY_PATH,
  createCategory,
  editCat,
  contractPayFast,
  contractTransform
} from "../../services/procedure";
import {
  API_TRANSACTION_PATH,
  getListTransactions,
  updateTransactions
} from "../../services/transaction";

const initialState = {
  list: [],
  listByClient: [],
  listTrans: [],
  contract: {},
  listOnTime: [],
  listDelay: []
};
export const getContractDetail = createAsyncThunk(
  `${API_PROCEDURE_PATH}/${common.detail}`,
  async id => {
    const res = await detail(id);
    return res;
  }
);
export const getProcedureList = createAsyncThunk(
  `${API_PROCEDURE_PATH}/${common.list}`,
  async () => {
    const res = await getAllProcedures();
    return res;
  }
);
export const getProcedureListByClient = createAsyncThunk(
  `${API_PROCEDURE_PATH}/list-by-client`,
  async (filter = "") => {
    const res = await getProceduresByClient(filter);
    return res;
  }
);
export const getListContractOnTime = createAsyncThunk(
  `${API_PROCEDURE_PATH}/list-on-time`,
  async () => {
    const res = await getProceduresByClient("?status=ontime");
    return res;
  }
);
export const getListContractDelay = createAsyncThunk(
  `${API_PROCEDURE_PATH}/list-delay`,
  async () => {
    const res = await getProceduresByClient("?status=delay");
    return res;
  }
);

export const getProcedureListMasseClient = createAsyncThunk(
  `${API_PROCEDURE_PATH}/list-by-client`,
  async clientId => {
    const res = await getProceduresMasseClient(clientId);
    return res;
  }
);

export const createNewProcedure = createAsyncThunk(
  `${API_PROCEDURE_PATH}/create`,
  async procedure => {
    const res = await create(procedure);
    return { ...res, procedure };
  }
);
export const createNewProcedureCat = createAsyncThunk(
  `${API_PROCEDURE_CATEGORY_PATH}/${common.create}`,
  async procedure => {
    const res = await createCategory(procedure);
    return { ...res, procedure };
  }
);

export const editProcedure = createAsyncThunk(
  `${API_PROCEDURE_PATH}/${common.edit}`,
  async procedure => {
    const res = await edit(procedure);
    return res;
  }
);

export const editProcedureCat = createAsyncThunk(
  `${API_PROCEDURE_CATEGORY_PATH}/${common.edit}`,
  async procedure => {
    const res = await editCat(procedure);
    return { procedure, res };
  }
);

export const deleteProcedure = createAsyncThunk(
  `${API_PROCEDURE_CATEGORY_PATH}/${common.delete}`,
  async id => {
    const res = await remove(id);
    return { ...res };
  }
);

export const contractTransformSlice = createAsyncThunk(
  `${API_PROCEDURE_CATEGORY_PATH}/contract-transform`,
  async ({ contractId, data }) => {
    const res = await contractTransform(contractId, data);
    return { ...res };
  }
);

export const closeContract = createAsyncThunk(
  `${API_PROCEDURE_PATH}/close-contract`,
  async contract => {
    const res = await edit(contract);
    return res;
  }
);

export const deleteProcedureCategory = createAsyncThunk(
  `${API_PROCEDURE_CATEGORY_PATH}/${common.delete}`,
  async id => {
    await removeCategory(id);
  }
);

export const updateMasseProcedure = createAsyncThunk(
  `${API_PROCEDURE_PATH}/update-masse-procedure`,
  async data => {
    await updateMasse(data);
  }
);

export const listTransactions = createAsyncThunk(
  `${API_TRANSACTION_PATH}/list-transaction`,
  async contractId => getListTransactions(contractId)
);
export const updateTransactionSlice = createAsyncThunk(
  `${API_TRANSACTION_PATH}/updateTransaction`,
  async (contractId, transactionId, data) =>
    updateTransactions(contractId, transactionId, data)
);
export const contractPayFastSlice = createAsyncThunk(
  `${API_TRANSACTION_PATH}/contractPayFast`,
  async (contractId, data) => contractPayFast(contractId, data)
);
export const procedureSlice = createSlice({
  name: "procedure",
  initialState,
  reducers: {
    setProcedure: (state, { payload }) => {
      state.procedure = payload;
    },
    setProcedureList: (state, { payload }) => {
      state.list = payload;
    },
    setMasseList: (state, { payload }) => {
      state.listByClient = payload;
    },
    setListTrans: (state, { payload }) => {
      state.listTrans = payload;
    },

    setDelayList: (state, { payload }) => {
      state.listDelay = payload;
    }
  },
  extraReducers: {
    [getProcedureList.fulfilled]: (state, { payload }) => {
      if (payload.status) {
        const { data } = payload.data;
        state.list = data;
      }
    },
    [getProcedureListByClient.fulfilled]: (state, { payload }) => {
      if (payload.status) {
        const { data } = payload.data;
        state.listByClient = data;
      }
    },
    [getListContractOnTime.fulfilled]: (state, { payload }) => {
      if (payload.status) {
        const { data } = payload.data;
        state.listOnTime = data;
      }
    },
    [getListContractDelay.fulfilled]: (state, { payload }) => {
      if (payload.status) {
        const { data } = payload.data;
        state.listDelay = data;
      }
    },
    [createNewProcedure.fulfilled]: (state, { payload }) => {
      if (payload.status) {
        state.list.push(payload.procedure);
      }
    },
    [getContractDetail.fulfilled]: (state, { payload }) => {
      if (payload.status) {
        const { data } = payload.data;
        state.contract = data;
      }
    },
    [listTransactions.fulfilled]: (state, { payload }) => {
      if (payload.status) {
        const { data } = payload.data;
        state.listTrans = data;
      }
    },
    [updateTransactionSlice.fulfilled]: (state, { payload }) => {
      if (payload.status) {
        const { data } = payload.data;
        console.log(data);
        // state.listTrans = data;
      }
    },
    [contractPayFastSlice.fulfilled]: (state, { payload }) => {
      if (payload.status) {
        const { data } = payload.data;
        console.log(data);
        // state.listTrans = data;
      }
    },
    [contractTransformSlice.fulfilled]: (state, { payload }) => {
      if (payload.status) {
        const { data } = payload.data;
        console.log(data);
        // state.listTrans = data;
      }
    }
    // [closeContract.fulfilled]: (state, { payload }) => {
    //   if (payload.status) {
    //     // console.log("1111");
    //     const { data } = payload.data;
    //     console.log(data);
    //   }
    // },
  }
});

export const {
  setProcedure,
  setProcedureList,
  setMasseList,
  setListTrans,
  setDelayList
} = procedureSlice.actions;

export default procedureSlice.reducer;
