import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  API_PATH,
  createUser,
  deleteUser,
  getStatistic,
  getTranToday,
  listUser,
  profile,
  resetPassword
} from "../../services/auth";

const initialState = {
  list: [],
  current: null,
  profile: null,
  statistic: {},
  listTranDayHistory: []
};

export const getListAccount = createAsyncThunk(
  `${API_PATH}/list-user`,
  async () => listUser()
);

export const getProfileUser = createAsyncThunk(`${API_PATH}`, async () =>
  profile()
);

export const createAccount = createAsyncThunk(
  `${API_PATH}/create-user`,
  async data => createUser(data)
);

export const deleteAccount = createAsyncThunk(
  `${API_PATH}/delete-user`,
  async id => deleteUser(id)
);

export const changPassword = createAsyncThunk(
  `${API_PATH}/reset-password`,
  async data => resetPassword(data)
);

export const statisticData = createAsyncThunk(
  `${API_PATH}/statistic`,
  async () => getStatistic()
);

export const listTransInDay = createAsyncThunk(
  `${API_PATH}/dayHistory`,
  async () => getTranToday()
);
export const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    setList: (state, { payload }) => {
      state.list = payload;
    },
    setProfileUser: (state, { payload }) => {
      state.profile = payload;
    },
    setStatisticData: (state, { payload }) => {
      state.statistic = payload;
    },
    setListTransInDay: (state, { payload }) => {
      state.listTranDayHistory = payload;
    }
  },
  extraReducers: {
    [getListAccount.fulfilled]: (state, { payload }) => {
      if (payload.status) {
        state.list = payload?.data?.data || [];
      }
    },
    [getProfileUser.fulfilled]: (state, { payload }) => {
      if (payload.status) {
        state.profile = payload?.data?.data || {};
      }
    },
    [statisticData.fulfilled]: (state, { payload }) => {
      if (payload.status) {
        state.statistic = payload?.data?.data || {};
      }
    },
    [listTransInDay.fulfilled]: (state, { payload }) => {
      if (payload.status) {
        state.listTranDayHistory = payload?.data?.data || [];
      }
    }
  }
});

export const { setList } = accountSlice.actions;

export default accountSlice.reducer;
