import { Helmet } from "react-helmet";
import { Box, Container, Grid } from "@material-ui/core";
import Budget from "src/components/dashboard/Budget";
import LatestOrders from "src/components/dashboard/LatestOrders";
import AmountLending from "src/components/dashboard/AmountLending";
import TotalContract from "src/components/dashboard/TotalContract";
import TotalProfit from "src/components/dashboard/TotalProfit";
import { useEffect } from "react";
import useAccount from "../hooks/useAccount";

const Dashboard = () => {
  const {
    getDashboard,
    statistic,
    getListTrans,
    listTranDayHistory
  } = useAccount();
  useEffect(async () => {
    await getDashboard();
    await getListTrans();
  }, []);
  return (
    <>
      <Helmet>
        <title>Trang chủ | Lending App</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <Grid container spacing={3}>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <Budget statistic={statistic} />
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <TotalContract statistic={statistic} />
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <AmountLending statistic={statistic} />
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <TotalProfit sx={{ height: "100%" }} statistic={statistic} />
            </Grid>
            <Grid item lg={12} md={12} xl={12} xs={12}>
              <LatestOrders listTranDayHistory={listTranDayHistory} />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Dashboard;
