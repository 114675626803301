import { configureStore } from "@reduxjs/toolkit";
import accountReducer from "./slices/accountSlice";
import userReducer from "./slices/userSlice";
import procedureReducer from "./slices/procedureSlice";
import procedureCategoryReducer from "./slices/procedureCategorySlice";
import transactionReducer from "./slices/transactionSlice";
import capitalReducer from "./slices/capitalSlice";

const store = configureStore({
  reducer: {
    account: accountReducer,
    user: userReducer,
    procedure: procedureReducer,
    procedureCategory: procedureCategoryReducer,
    transaction: transactionReducer,
    capital: capitalReducer
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false
    })
});
export default store;
