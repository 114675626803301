import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { Box, Divider, Drawer, Hidden, List } from "@material-ui/core";
import {
  BarChart as BarChartIcon,
  ShoppingBag as ShoppingBagIcon,
  // User as UserIcon,
  Users as UsersIcon,
  Database as DataBaseIcon
} from "react-feather";
import NavItem from "./NavItem";

const items = [
  {
    href: "/app/dashboard",
    icon: BarChartIcon,
    title: "Trang chủ"
  },
  {
    href: "/app/accounts",
    icon: UsersIcon,
    title: "Tài khoản"
  },
  {
    href: "/app/gop-von",
    icon: DataBaseIcon,
    title: "Quản lý nguồn vốn"
  },
  // {
  //   href: "/app/tin-chap",
  //   icon: ShoppingBagIcon,
  //   title: "Tín chấp"
  // },
  {
    href: "/app/tra-gop",
    icon: ShoppingBagIcon,
    title: "Trả góp"
  }
];

const DashboardSidebar = ({ onMobileClose, openMobile }) => {
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%"
      }}
    >
      <Divider />
      <Box sx={{ p: 2 }}>
        <List>
          {items.map(item => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              width: 256
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: 256,
              top: 64,
              height: "calc(100% - 64px)"
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default DashboardSidebar;
