import * as Yup from "yup";

export const contractCreate = () =>
  Yup.object().shape({
    customerName: Yup.string()
      .max(255)
      .required("Vui lòng nhập tên khách hàng"),
    loanAmount: Yup.number()
      .min(1)
      .required("Vui lòng nhập số tiền"),
    receiveAmount: Yup.number()
      .min(1)
      .required("Vui lòng nhập số tiền"),
    period: Yup.string()
      .max(255)
      .required("Vui lòng nhập hình thức"),
    numberPeriod: Yup.number()
      .min(1)
      .required("Vui lòng nhập số ngày"),
    duration: Yup.number()
      .min(1)
      .required("Vui lòng nhập số ngày"),
    date: Yup.date().required("Vui lòng nhập ngày")
  });

export const contractEdit = () =>
  Yup.object().shape({
    customerName: Yup.string()
      .max(255)
      .required("Vui lòng nhập tên khách hàng"),
    loanAmount: Yup.number()
      .min(1)
      .required("Vui lòng nhập số tiền"),
    receiveAmount: Yup.number()
      .min(1)
      .required("Vui lòng nhập số tiền"),
    period: Yup.string()
      .max(255)
      .required("Vui lòng nhập hình thức"),
    numberPeriod: Yup.number()
      .min(1)
      .required("Vui lòng nhập số ngày"),
    duration: Yup.number()
      .min(1)
      .required("Vui lòng nhập số ngày"),
    date: Yup.date().required("Vui lòng nhập ngày")
  });
export const contractRotate = () =>
  Yup.object().shape({
    loanAmount: Yup.number()
      .min(1)
      .required("Vui lòng nhập số tiền"),
    receiveAmount: Yup.number()
      .min(1)
      .required("Vui lòng nhập số tiền"),
    numberPeriod: Yup.number()
      .min(1)
      .required("Vui lòng nhập số ngày"),
    duration: Yup.number()
      .min(1)
      .required("Vui lòng nhập số ngày"),
    date: Yup.date().required("Vui lòng nhập ngày")
  });
export const contractCapital = () =>
  Yup.object().shape({
    amount: Yup.number()
      .min(1)
      .required("Vui lòng nhập số tiền")
  });
