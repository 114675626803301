import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";

const filterOptions = createFilterOptions({
  matchFrom: "any",
  limit: 500
});

const SearchDropdown = ({
  title,
  data,
  value,
  id,
  error,
  onBlur = () => {},
  setFieldValue,
  onChange = () => {},
  helperText,
  readOnly = false,
  hideForm = false
}) => {
  const selectedValue = data.find(item => item.id === value);

  return (
    <FormControl
      fullWidth
      margin="normal"
      size="small"
      sx={{ display: hideForm ? "none" : "" }}
    >
      <Autocomplete
        fullWidth
        margin="normal"
        size="small"
        readOnly={readOnly}
        value={selectedValue || null}
        filterOptions={filterOptions}
        onChange={async (e, newValue) => {
          await setFieldValue(id, newValue !== null ? newValue.id : value);
          onChange(newValue);
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        id={id}
        options={data}
        getOptionLabel={option => option.label || ""}
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            {option.label}
          </li>
        )}
        renderInput={(params, key) => (
          <TextField
            {...params}
            error={error}
            helperText={helperText}
            onBlur={onBlur}
            label={title}
            name={id}
            key={key}
            variant="outlined"
          />
        )}
      />
    </FormControl>
  );
};
SearchDropdown.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  value: PropTypes.any,
  id: PropTypes.string.isRequired,
  error: PropTypes.bool.isRequired,
  setFieldValue: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  helperText: PropTypes.string,
  readOnly: PropTypes.bool,
  hideForm: PropTypes.bool
};

export default SearchDropdown;
