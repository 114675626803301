import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

const Title = ({ id = "Lending App" }) => (
  <Helmet>
    <title>{id}</title>
  </Helmet>
);

Title.propTypes = {
  id: PropTypes.string.isRequired
};

export default Title;
