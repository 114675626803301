import * as React from "react";
import { Box, CircularProgress } from "@material-ui/core";

const CustomLinearProgress = () => (
  <Box
    sx={{
      // position: "fixed",
      // top: "0px",
      // left: "0px",
      // zIndex: 99999,
      // width: "100%"
      display: "flex",
      justifyContent: "center"
    }}
  >
    <CircularProgress color="secondary" />
  </Box>
);

export default CustomLinearProgress;
