import { useState } from "react";
import { useAppDispatch, useAppSelector } from ".";
import {
  changPassword,
  createAccount,
  deleteAccount,
  getListAccount,
  getProfileUser,
  statisticData,
  listTransInDay
} from "../store/slices/accountSlice";

const useAccount = () => {
  const [loading, setLoading] = useState(false);
  const {
    list,
    current,
    profile,
    statistic,
    listTranDayHistory
  } = useAppSelector(state => state.account);
  const dispatch = useAppDispatch();

  const getList = async () => {
    setLoading(true);
    try {
      const res = await dispatch(getListAccount());
      return res.payload;
    } finally {
      setLoading(false);
    }
  };

  const getListTrans = async () => {
    setLoading(true);
    try {
      const res = await dispatch(listTransInDay());
      return res.payload;
    } finally {
      setLoading(false);
    }
  };

  const create = async data => {
    setLoading(true);
    try {
      const res = await dispatch(createAccount(data));
      return res.payload;
    } finally {
      setLoading(false);
    }
  };

  const getProfile = async () => {
    setLoading(true);
    try {
      const res = await dispatch(getProfileUser());
      return res.payload;
    } finally {
      setLoading(false);
    }
  };

  const deleteProfile = async id => {
    setLoading(true);
    try {
      const res = await dispatch(deleteAccount(id));
      return res.payload;
    } finally {
      setLoading(false);
    }
  };

  const changePass = async data => {
    setLoading(true);
    try {
      const res = await dispatch(changPassword(data));
      return res.payload;
    } finally {
      setLoading(false);
    }
  };

  const getDashboard = async () => {
    setLoading(true);
    try {
      const res = await dispatch(statisticData());
      return res.payload;
    } finally {
      setLoading(false);
    }
  };

  return {
    list,
    profile,
    current,
    loading,
    getList,
    create,
    getProfile,
    deleteProfile,
    changePass,
    getDashboard,
    statistic,
    getListTrans,
    listTranDayHistory
  };
};

export default useAccount;
