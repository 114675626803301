import { Outlet } from "react-router-dom";
import { experimentalStyled } from "@material-ui/core";
import MainNavbar from "./MainNavbar";

const MainLayoutRoot = experimentalStyled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  display: "flex",
  height: "100%",
  // overflowX: 'hidden',
  width: "100%"
}));

const MainLayoutWrapper = experimentalStyled("div")({
  display: "flex",
  flex: "1 1 auto",
  // overflowX: 'hidden',
  paddingTop: 64
});

const MainLayoutContainer = experimentalStyled("div")({
  display: "flex",
  flex: "1 1 auto"
  // overflowX: 'hidden'
});

const MainLayoutContent = experimentalStyled("div")({
  flex: "1 1 auto",
  height: "100%"
  // overflow: 'auto'
});

const MainLayout = () => (
  <MainLayoutRoot>
    <MainNavbar />
    <MainLayoutWrapper>
      <MainLayoutContainer>
        <MainLayoutContent>
          <Outlet />
        </MainLayoutContent>
      </MainLayoutContainer>
    </MainLayoutWrapper>
  </MainLayoutRoot>
);

export default MainLayout;
