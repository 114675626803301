import { Box, Card, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import CustomTable from "../common/CustomTable";

const LatestOrders = ({ listTranDayHistory, ...props }) => {
  const options = {
    filterType: "checkbox",
    selectableRows: false,
    filter: false,
    download: false,
    print: false,
    downloadOptions: {
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true
      }
    }
  };
  const data = listTranDayHistory.map(item => ({
    amount: item.amount,
    ...item.contract
  }));
  const columns = [
    {
      name: "id",
      label: "Mã HĐ",
      options: {
        customBodyRender: value => <Box>HĐ - {value}</Box>
      }
    },
    {
      name: "customerName",
      label: "Tên khách hàng "
    },
    {
      name: "amount",
      label: "Số tiền giao dịch",
      options: {
        customBodyRender: (value = 0) => value.toLocaleString("en-US")
      }
    },
    {
      name: "id",
      label: "Trạng thái",
      options: {
        customBodyRender: () => "Lãi trong ngày"
      }
    },
    {
      name: "receiver",
      label: "Nhân viên"
    },
    {
      name: "updatedAt",
      label: "Giờ giao dịch",
      options: {
        customBodyRender: value => dayjs(value).format("DD/MM/YYYY HH:mm:ss")
      }
    }
  ];
  return (
    <Card {...props}>
      <CustomTable
        title={<Typography variant="h2">Giao dịch trong ngày</Typography>}
        data={data}
        options={options}
        columns={columns}
        id="giao-dich-trong-ngay"
      />
    </Card>
  );
};

LatestOrders.propTypes = {
  listTranDayHistory: PropTypes.array.isRequired
};

export default LatestOrders;
