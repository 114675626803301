import { Avatar, Card, CardContent, Grid, Typography } from "@material-ui/core";
import { orange } from "@material-ui/core/colors";
import InsertChartIcon from "@material-ui/icons/InsertChartOutlined";
import PropTypes from "prop-types";

const AmountLending = ({ statistic, ...props }) => (
  <Card sx={{ height: "100%" }} {...props}>
    <CardContent>
      <Grid container spacing={3} sx={{ justifyContent: "space-between" }}>
        <Grid item>
          <Typography color="textSecondary" gutterBottom variant="h6">
            TIỀN ĐANG CHO VAY
          </Typography>
          <Typography color="textPrimary" variant="h3">
            {statistic.amountLending?.toLocaleString("en-US") || 0}
          </Typography>
        </Grid>
        <Grid item>
          <Avatar
            sx={{
              backgroundColor: orange[600],
              height: 56,
              width: 56
            }}
          >
            <InsertChartIcon />
          </Avatar>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);

AmountLending.propTypes = {
  statistic: PropTypes.object
};

export default AmountLending;
