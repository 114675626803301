import httpClient from ".";

export const API_PATH = "user";
export const login = data => httpClient.post(`${API_PATH}/login`, data);

export const listUser = () => httpClient.get(`${API_PATH}/list-user`);

export const profile = () => httpClient.get(`${API_PATH}`);

export const createUser = data => httpClient.post(`${API_PATH}`, data);

export const deleteUser = id => httpClient.delete(`${API_PATH}/${id}`);
export const forgotPassword = data =>
  httpClient.post(`${API_PATH}/forgot-password`, data);
export const resetPassword = data =>
  httpClient.post(`${API_PATH}/reset-password`, data);

export const getStatistic = () => httpClient.get(`${API_PATH}/statistics`);

export const getTranToday = () =>
  httpClient.get(`${API_PATH}/transactionInDay`);
export const resetPasswordToken = data =>
  httpClient.post(`${API_PATH}/token-expired`, data);

export const confirmCode = data =>
  httpClient.post(`${API_PATH}/confirm-passcode-auth`, data);
