import httpClient from ".";

export const API_TRANSACTION_PATH = "transaction";

export const getListTransactions = contractId =>
  httpClient.get(`${API_TRANSACTION_PATH}/${contractId}`);

export const updateTransactions = ({ contractId, transactionId, data }) =>
  httpClient.post(
    `${API_TRANSACTION_PATH}/${contractId}/${transactionId}`,
    data
  );
