import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Box, Container, Tooltip, Typography } from "@material-ui/core";
import dayjs from "dayjs";
import { NavLink as RouterLink } from "react-router-dom";
import { XSquare as XSquareIcon } from "react-feather";
import { toast } from "react-toastify";
import useAccount from "../hooks/useAccount";
// import AccountListResults from "../components/account/AccountListResults";
import DialogAddAccount from "../components/account/DialogAddAccount";
import CustomTable from "../components/common/CustomTable";
import DialogChangePass from "../components/account/DialogChangePass";
import CustomLinearProgress from "../components/common/CustomLinearProgress";

const AccountList = () => {
  const {
    list: users,
    getList,
    deleteProfile,
    loading
    // profile
  } = useAccount();
  useEffect(async () => {
    await getList();
  }, []);
  const options = {
    filterType: "checkbox",
    selectableRows: false,
    downloadOptions: {
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true
      }
    }
  };
  const handleClickDelete = async id => {
    const res = await deleteProfile(id);
    if (!res?.data?.success) {
      toast.error(res?.error?.message);
    } else {
      toast.success("Xoá thành công");
    }
    await getList();
  };
  const columns = [
    {
      name: "id",
      label: "#",
      options: {
        customBodyRender: (value, tableMeta) => {
          const { rowIndex } = tableMeta;
          return <Box>{rowIndex + 1}</Box>;
        }
      }
    },
    {
      name: "userName",
      label: "Email"
    },
    {
      name: "role",
      label: "Vai trò",
      options: {
        customBodyRender: value => value === 'admin' ? 'Quản trị' : 'Nhân viên'
      }
    },
    {
      name: "status",
      label: "Trạng thái",
      options: {
        customBodyRender: value => value === 'active' ? 'Hoạt động' : 'Ngừng hoạt động'
      }
    },
    {
      name: "createdAt",
      label: "Ngày tạo",
      options: {
        customBodyRender: value => `${dayjs(value).format("DD/MM/YYYY")}`
      }
    },
    {
      name: "id",
      label: "Chức năng",
      options: {
        customBodyRender: value => {
          const user = users.find(p => p.id === value);
          return (
            <Box
              sx={{
                display: "flex"
              }}
            >
              <DialogChangePass userId={user.id} />
              <RouterLink
                to="#"
                className="mr-1 rounded-full bg-secondary text-action-color p-2"
              >
                <Tooltip
                  title="Xóa tài khoản"
                  placement="top"
                  onClick={() => handleClickDelete(user.id)}
                >
                  <XSquareIcon size={16} />
                </Tooltip>
              </RouterLink>
            </Box>
          );
        }
      }
    }
  ];

  return (
    <>
      <Helmet>
        <title>Danh sách người dùng | Lending App</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3
        }}
      >
        <Container maxWidth={false}>
          {/* <h1>DANH SÁCH NGƯỜI DÙNG</h1> */}
          {/* <DialogAddAccount /> */}
          {/* <Box sx={{ pt: 3 }}> */}
          {/*  <AccountListResults users={users} profile={profile} /> */}
          {/* </Box> */}
          <DialogAddAccount />
          <Box sx={{ pt: 3 }}>
            {loading ? (
              <CustomLinearProgress />
            ) : (
              <CustomTable
                title={
                  <Typography variant="h2">Danh sách người dùng</Typography>
                }
                data={users}
                options={options}
                columns={columns}
                id="danh-sach-nguoi-dung"
              />
            )}
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default AccountList;
