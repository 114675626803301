import { Avatar, Card, CardContent, Grid, Typography } from "@material-ui/core";
import MoneyIcon from "@material-ui/icons/Money";
import { red } from "@material-ui/core/colors";
import PropTypes from "prop-types";

const Budget = ({ statistic, ...props }) => (
  <Card sx={{ height: "100%" }} {...props}>
    <CardContent>
      <Grid container spacing={3} sx={{ justifyContent: "space-between" }}>
        <Grid item>
          <Typography color="textSecondary" gutterBottom variant="h6">
            TỔNG QUỸ TIỀN MẶT
          </Typography>
          <Typography color="textPrimary" variant="h3">
            {statistic.amount?.toLocaleString("en-US") || 0}
          </Typography>
        </Grid>
        <Grid item>
          <Avatar
            sx={{
              backgroundColor: red[600],
              height: 56,
              width: 56
            }}
          >
            <MoneyIcon />
          </Avatar>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);

Budget.propTypes = {
  statistic: PropTypes.object
};

export default Budget;
