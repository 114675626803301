import { useEffect } from "react";
import { NavLink as RouterLink, useLocation } from "react-router-dom";
import {
  DollarSign as DollarSignIcon,
  Edit as EditIcon,
  Scissors as ScissorsIcon,
  XSquare as XSquareIcon
} from "react-feather";
import {
  Box,
  Button,
  Chip,
  Container,
  Tooltip,
  Typography
} from "@material-ui/core";
import queryString from "query-string";
import dayjs from "dayjs";
import useProcedureManagement from "../hooks/useProcedureManagement";
import Title from "../components/common/title";
import ContractDetail from "./ContractDetail";
import ContractAdd from "./ContractAdd";
import CustomTable from "../components/common/CustomTable";
import ConfirmationDialog from "../components/common/ConfirmationDialog";
import ContractEdit from "./ContractEdit";
import CustomLinearProgress from "../components/common/CustomLinearProgress";

const status = {
  closed: "Đóng hợp đồng",
  ontime: "Đến ngày đóng họ",
  pending: "Đang vay",
  late: "Chậm họ",
  expired: "Quá hạn",
  lastday: "Ngày cuối họ",
  deleted: "Đã xóa"
};
const statusColor = {
  closed: "success",
  ontime: "primary",
  pending: "secondary",
  late: "error",
  expired: "warning",
  lastday: "info",
  deleted: "primary"
};
const Procedure = () => {
  const {
    loading,
    listByClient: procedures,
    getListByClient,
    handleClose,
    open,
    handleCloseCat,
    handleOpenCat,
    openCat,
    dataCat,
    handleOpen,
    openEdit,
    dataEdit,
    handleOpenEdit,
    handleCloseEdit,
    openModalDelete,
    deleteId,
    remove,
    contractPayFast,
    deleteModalContent,
    handleOpenModalDelete,
    handleCloseModalDelete,
    tab
  } = useProcedureManagement();
  // const location = useLocation();
  // useEffect(() => {
  //   const params = queryString.parse(location.search);
  //   const filter = params.status ? `?status=${params.status}` : "";
  //   getListByClient(filter);
  // }, [location]);
  const location = useLocation();
  const params = queryString.parse(location.search);
  const filter = params.status ? `?status=${params.status}` : "";
  useEffect(() => {
    getListByClient(filter);
  }, [location]);

  const options = {
    filterType: "checkbox",
    selectableRows: false,
    downloadOptions: {
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true
      }
    }
  };

  const handleDetail = (event, contract) => {
    event.preventDefault();
    handleOpenCat(contract);
  };
  const handleEdit = (event, contract) => {
    event.preventDefault();
    handleOpenEdit(contract);
  };
  const handleDelete = (event, title, id) => {
    event.preventDefault();
    handleOpenModalDelete(title, id);
  };
  const handleContractClose = (event, contract) => {
    event.preventDefault();
    handleOpenCat(contract, "2");
  };
  const handlePaidFast = (event, contractId) => {
    const amount = parseInt(event.target.value);
    event.target.style.backgroundColor = "#f4516c";
    event.target.style.color = "white";
    event.target.style.borderColor = "#f4516c";
    contractPayFast(contractId, { amount });
  };
  const handleExportExcel = () => {
    const icon = document.querySelector("[data-testid='CloudDownloadIcon']");
    const parentIcon = icon.parentNode;
    if (parentIcon) {
      parentIcon.click();
    }
  };

  let columns = [
    {
      name: "id",
      label: "#",
      options: {
        customBodyRender: (value, tableMeta) => {
          const { rowIndex } = tableMeta;
          return <Box>{rowIndex + 1}</Box>;
        }
      }
    },
    {
      name: "id",
      label: "Mã HĐ",
      options: {
        customBodyRender: value => <Box>HĐ - {value}</Box>
      }
    },
    {
      name: "customerName",
      label: "Khách hàng"
    },
    {
      name: "receiveAmount",
      label: "Tiền giao khách",
      hasFilter: false,
      options: {
        customBodyRender: value => value.toLocaleString("en-US")
      }
    },
    {
      name: "loanAmount",
      label: "",
      hasFilter: false,
      options: {
        display: false,
        filter: false
      }
    },
    {
      name: "ratio",
      label: "Tỷ lệ",
      hasFilter: false,
      options: {
        filter: false
      }
    },
    {
      name: "date",
      label: "",
      options: {
        display: false,
        filter: false
      }
    },
    {
      name: "numberPeriod",
      label: "",
      options: {
        display: false,
        filter: false
      }
    },
    {
      name: "duration",
      label: "Thời gian",
      options: {
        customBodyRender: (value, tableMeta) => {
          const toTime = dayjs(tableMeta.rowData[6]).add(
            tableMeta.rowData[7],
            "day"
          );
          return `${dayjs(tableMeta.rowData[6]).format("DD/MM")} - ${dayjs(
            toTime
          ).format("DD/MM")}\n (${tableMeta.rowData[7]} ngày)`;
        },
        filter: false
      }
    },
    {
      name: "paidAmount",
      label: "Tiền đã đóng",
      options: {
        customBodyRender: value => value.toLocaleString("en-US"),
        filter: false
      }
    },
    {
      name: "amountPerDay",
      label: "Tiền 1 ngày",
      options: {
        customBodyRender: value => value.toLocaleString("en-US"),
        filter: false
      }
    },
    {
      name: "unpaidAmount",
      label: "Còn phải đóng",
      options: {
        customBodyRender: value => value.toLocaleString("en-US"),
        filter: false
      }
    },
    {
      name: "payDate",
      label: "",
      options: {
        display: false,
        filter: false
      }
    },
    {
      name: "duration",
      label: "",
      options: {
        display: false,
        filter: false
      }
    },
    {
      name: "status",
      label: "Tình trạng",
      options: {
        customBodyRender: value => (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center"
            }}
          >
            <Chip label={status[value]} color={statusColor[value]} />
          </Box>
        )
      }
    },
    {
      name: "dateToPay",
      label: "Ngày phải đóng",
      options: {
        customBodyRender: (value, tableMeta) => {
          const toTime = dayjs(tableMeta.rowData[12]).add(
            tableMeta.rowData[13],
            "day"
          );
          return `${toTime.format("DD/MM/YYYY")}`;
        }
      }
    },
    {
      name: "id",
      label: "Chức năng",
      hasFilter: false,
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: { textAlign: "center", width: "100px" }
        }),
        customBodyRender: value => {
          const procedure = procedures.find(p => p.id === value);
          return (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between"
              }}
            >
              <RouterLink
                onClick={event => handleDetail(event, procedure)}
                className="mr-1 rounded-full bg-primary text-action-color p-2"
                to="#"
              >
                <Tooltip title="Đóng tiền" placement="top">
                  <DollarSignIcon size={16} />
                </Tooltip>
              </RouterLink>
              <RouterLink
                onClick={event => handleEdit(event, procedure)}
                className="mr-1 rounded-full bg-secondary text-action-color p-2"
                to="#"
              >
                <Tooltip title="Sửa HĐ" placement="top">
                  <EditIcon size={16} />
                </Tooltip>
              </RouterLink>
              <RouterLink
                onClick={event =>
                  handleDelete(event, "Xóa hợp đồng", procedure.id)
                }
                className="mr-1 rounded-full bg-warning text-action-color p-2"
                to="#"
              >
                <Tooltip title="Xóa HĐ" placement="top">
                  <XSquareIcon size={16} />
                </Tooltip>
              </RouterLink>
              <RouterLink
                onClick={event => handleContractClose(event, procedure)}
                className="rounded-full bg-success text-action-color p-2"
                to="#"
              >
                <Tooltip title="Đóng HĐ" placement="top">
                  <ScissorsIcon size={16} />
                </Tooltip>
              </RouterLink>
            </Box>
          );
        }
      }
    }
  ];
  if (params.status === "delay") {
    columns = [
      {
        name: "id",
        label: "#",
        options: {
          customBodyRender: (value, tableMeta) => {
            const { rowIndex } = tableMeta;
            return <Box>{rowIndex + 1}</Box>;
          }
        }
      },
      {
        name: "id",
        label: "Mã HĐ",
        options: {
          customBodyRender: value => <Box>HĐ - {value}</Box>
        }
      },
      {
        name: "customerName",
        label: "Khách hàng"
      },
      {
        name: "loanAmount",
        label: "",
        options: {
          display: false,
          filter: false
        }
      },
      {
        name: "numberPeriod",
        label: "",
        options: {
          display: false,
          filter: false
        }
      },
      {
        name: "paidAmount",
        label: "",
        options: {
          display: false,
          filter: false
        }
      },
      {
        name: "address",
        label: "Địa chỉ"
      },
      {
        name: "textNotice",
        label: "Lý do",
        hasFilter: false
      },
      {
        name: "amountDelay",
        label: "Tiền cần đóng",
        hasFilter: false,
        options: {
          customBodyRender: value => value.toLocaleString("en-US")
        }
      },
      {
        name: "numberDayDelay",
        label: "Đóng tiền nhanh",
        hasFilter: false,
        options: {
          customBodyRender: (value, tableMeta) => {
            const amount1Day = Math.round(
              tableMeta.rowData[3] / tableMeta.rowData[4]
            );
            const contractId = tableMeta.rowData[1];
            const amountList = [];
            let maxValue = parseInt(value);
            if (maxValue > 10) {
              maxValue = 10;
            }
            for (let i = 1; i <= maxValue; i++) {
              amountList.push(i);
            }
            return (
              <Box>
                {amountList.map(item => (
                  <Button
                    onClick={event => {
                      handlePaidFast(event, contractId);
                    }}
                    variant="outlined"
                    color="success"
                    sx={{ mr: 1, mb: 1 }}
                    key={item}
                    value={item * amount1Day}
                  >
                    {(item * amount1Day) / 1000}
                  </Button>
                ))}
              </Box>
            );
          }
        }
      },
      {
        name: "status",
        label: "Tình trạng",
        options: {
          customBodyRender: value => (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center"
              }}
            >
              <Chip label={status[value]} color={statusColor[value]} />
            </Box>
          )
        }
      },
      {
        name: "id",
        label: "Chức năng",
        hasFilter: false,
        options: {
          filter: false,
          sort: false,
          setCellProps: () => ({
            style: { textAlign: "center", width: "100px" }
          }),
          customBodyRender: value => {
            const procedure = procedures.find(p => p.id === value);
            return (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between"
                }}
              >
                <RouterLink
                  onClick={event => handleDetail(event, procedure)}
                  className="mr-1 rounded-full bg-primary text-action-color p-2"
                  to="#"
                >
                  <Tooltip title="Đóng tiền" placement="top">
                    <DollarSignIcon size={16} />
                  </Tooltip>
                </RouterLink>
                <RouterLink
                  onClick={event => handleEdit(event, procedure)}
                  className="mr-1 rounded-full bg-secondary text-action-color p-2"
                  to="#"
                >
                  <Tooltip title="Sửa HĐ" placement="top">
                    <EditIcon size={16} />
                  </Tooltip>
                </RouterLink>
                <RouterLink
                  onClick={event =>
                    handleDelete(event, "Xóa hợp đồng", procedure.id)
                  }
                  className="mr-1 rounded-full bg-warning text-action-color p-2"
                  to="#"
                >
                  <Tooltip title="Xóa HĐ" placement="top">
                    <XSquareIcon size={16} />
                  </Tooltip>
                </RouterLink>
                <RouterLink
                  onClick={event => handleContractClose(event, procedure)}
                  className="rounded-full bg-success text-action-color p-2"
                  to="#"
                >
                  <Tooltip title="Đóng HĐ" placement="top">
                    <ScissorsIcon size={16} />
                  </Tooltip>
                </RouterLink>
              </Box>
            );
          }
        }
      }
    ];
  }
  let pageTitle = "Hợp đồng Trả góp";
  if (params.status === "delay") {
    pageTitle = "Cảnh Báo Trả Góp";
  }
  return (
    <>
      <Title id={pageTitle} />
      {openCat && (
        <ContractDetail
          open={openCat}
          data={dataCat}
          handleClose={handleCloseCat}
          tab={tab}
        />
      )}
      {openEdit && (
        <ContractEdit
          open={openEdit}
          handleClose={handleCloseEdit}
          data={dataEdit}
        />
      )}
      <ContractAdd open={open} handleClose={handleClose} />
      <ConfirmationDialog
        open={openModalDelete}
        handleClose={handleCloseModalDelete}
        onSubmit={() => remove(deleteId)}
        title="Xóa hợp đồng"
        confirmTitle="Bạn có chắc chắn xóa hợp đồng này không?"
        content={deleteModalContent}
      />
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3
        }}
      >
        <Container maxWidth={false}>
          {params.status !== "delay" && (
            <Button
              color="primary"
              className="customButton"
              variant="contained"
              onClick={handleOpen}
              size="small"
            >
              Thêm mới
            </Button>
          )}

          <Button
            color="secondary"
            sx={{
              ml: 1
            }}
            size="small"
            className="customButton"
            variant="contained"
            onClick={handleExportExcel}
          >
            Xuất excel
          </Button>
          {/* <Button */}
          {/*  color="primary" */}
          {/*  className="customButton" */}
          {/*  variant="contained" */}
          {/*  onClick={handleOpen2} */}
          {/* > */}
          {/*  Form */}
          {/* </Button> */}
          <Box sx={{ pt: 3 }}>
            {loading ? (
              <CustomLinearProgress />
            ) : (
              <CustomTable
                title={<Typography variant="h2">{pageTitle}</Typography>}
                data={procedures}
                options={options}
                columns={columns}
                id="hop-dong-tra-gop"
              />
            )}
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Procedure;
