import * as React from "react";
import { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { InputLabel, MenuItem, Select } from "@mui/material";
import { toast } from "react-toastify";
import FormControl from "@material-ui/core/FormControl";
import useAccount from "../../hooks/useAccount";

// const roles = [
//   { label: "Quản trị", value: "admin", id: "admin", key: 1 },
//   { label: "Nhập liệu", value: "view", id: "view", key: 2 }
// ];

const DialogAddAccount = () => {
  const { create } = useAccount();
  const [open, setOpen] = useState(false);
  const [role, setRole] = useState("view");
  const { getList } = useAccount();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button variant="contained" onClick={handleClickOpen} size="small">
        Thêm mới
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          pt: 3
        }}
        PaperProps={{
          component: "form",
          onSubmit: async e => {
            e.preventDefault();
            const formData = new FormData(e.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const { email, password, amount } = formJson;
            const data = {
              userName: email,
              password,
              role,
              amount: parseInt(amount)
            };
            const res = await create(data);
            if (!res.success) {
              toast.error(res?.error?.message);
            }
            handleClose();
            await getList();
          }
        }}
      >
        <DialogTitle
          sx={{ fontSize: 20, textTransform: "uppercase", color: "white" }}
          className="bg-primary"
        >
          Thêm người dùng
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            required
            id="name"
            name="email"
            label="Email Address"
            type="email"
            size="small"
            margin="normal"
            fullWidth
            variant="outlined"
          />
          <TextField
            required
            id="pass"
            name="password"
            label="Mật khẩu"
            type="password"
            size="small"
            margin="normal"
            fullWidth
            variant="outlined"
          />
          <TextField
            required
            id="amount"
            name="amount"
            label="Quỹ"
            type="text"
            size="small"
            margin="normal"
            fullWidth
            variant="outlined"
          />
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Vai trò</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Age"
              sx={{
                height: 40
              }}
              onChange={e => {
                setRole(e.target.value);
              }}
            >
              <MenuItem value="admin">Quản trị</MenuItem>
              <MenuItem value="view">Nhập liệu</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions
          sx={{
            borderTop: "1px solid rgba(224, 224, 224, 1)"
          }}
        >
          <Button onClick={handleClose}>Huỷ</Button>
          <Button type="submit">Thêm</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default DialogAddAccount;
