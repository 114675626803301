import { useEffect } from "react";
import { Box, Button, Container, Typography } from "@material-ui/core";
import dayjs from "dayjs";
import useCapitalManagement from "../hooks/useCapitalManagement";
import Title from "../components/common/title";
// import CapitalDetail from "./CapitalDetail";
import CapitalAdd from "./CapitalAdd";
import CustomTable from "../components/common/CustomTable";
import CapitalEdit from "./CapitalEdit";
import CustomLinearProgress from "../components/common/CustomLinearProgress";

const investTypes = {
  invest: "Đầu tư"
};
const Capital = () => {
  const {
    loading,
    list = [],
    getList,
    handleClose,
    open,
    // handleOpenDetail,
    handleOpen,
    openEdit,
    dataEdit,
    handleCloseEdit
  } = useCapitalManagement();

  useEffect(() => {
    getList();
  }, []);

  const options = {
    filterType: "checkbox",
    selectableRows: false
  };
  const getIsLatestRow = tableMeta => {
    const { rowIndex, tableData } = tableMeta;
    return rowIndex + 1 === tableData.length;
  };
  const columns = [
    {
      name: "id",
      label: "#",
      options: {
        customBodyRender: (value, tableMeta) => {
          const isLatestRow = getIsLatestRow(tableMeta);
          const { rowIndex } = tableMeta;
          const valueText = isLatestRow ? "" : rowIndex + 1;
          return <Box>{valueText}</Box>;
        }
      }
    },
    {
      name: "userId",
      label: "Khách hàng",
      options: {
        customBodyRender: (value, tableMeta) => {
          const isLatestRow = getIsLatestRow(tableMeta);
          const valueText = isLatestRow ? "Tổng Tiền" : value;
          return (
            <Box
              sx={{
                color: isLatestRow ? "text.warning" : "",
                fontWeight: isLatestRow ? "bold" : "normal"
              }}
            >
              {valueText}
            </Box>
          );
        }
      }
    },
    {
      name: "amount",
      label: "Số tiền (VNĐ)",
      hasFilter: false,
      options: {
        customBodyRender: (value, tableMeta) => {
          const isLatestRow = getIsLatestRow(tableMeta);
          return (
            <Box
              sx={{
                color: isLatestRow ? "text.warning" : "",
                fontWeight: isLatestRow ? "bold" : "normal"
              }}
            >
              {value.toLocaleString("en-US")}
            </Box>
          );
        }
      }
    },
    {
      name: "createdAt",
      label: "Ngày góp",
      hasFilter: false,
      options: {
        customBodyRender: (value, tableMeta) => {
          const isLatestRow = getIsLatestRow(tableMeta);
          const valueText = isLatestRow
            ? ""
            : dayjs(value).format("DD/MM/YYYY");
          return <Box>{valueText}</Box>;
        }
      }
    },
    {
      name: "type",
      label: "Loại vốn",
      options: {
        customBodyRender: (value, tableMeta) => {
          const isLatestRow = getIsLatestRow(tableMeta);
          const valueText = isLatestRow ? "" : investTypes[value];
          return <Box>{valueText}</Box>;
        }
      }
    },
    {
      name: "status",
      label: "Tình trạng",
      options: {
        customBodyRender: (value, tableMeta) => {
          const isLatestRow = getIsLatestRow(tableMeta);
          const valueText = isLatestRow ? "" : "Đang đầu tư";
          return <Box>{valueText}</Box>;
        }
      }
    }
    // {
    //   name: "id",
    //   label: "Chức năng",
    //   hasFilter: false,
    //   options: {
    //     filter: true,
    //     sort: true,
    //     setCellProps: () => ({
    //       style: { width: "200px", textAlign: "center" }
    //     }),
    //     customBodyRender: value => {
    //       const cap = list.find(p => p.id === value);
    //       return (
    //         <Box
    //           sx={{
    //             display: "flex"
    //           }}
    //         >
    //           <RouterLink onClick={() => handleOpenEdit(cap)}>
    //             <EditIcon />
    //           </RouterLink>
    //         </Box>
    //       );
    //     }
    //   }
    // }
  ];
  return (
    <>
      <Title id="Hợp đồng Vốn" />
      {/* <CapitalDetailModal open={open} data={data} handleClose={handleClose} /> */}
      {/* {openCat && ( */}
      {/*  <CapitalDetail */}
      {/*    open={openCat} */}
      {/*    data={dataCat} */}
      {/*    handleClose={handleCloseCat} */}
      {/*  /> */}
      {/* )} */}
      {openEdit && (
        <CapitalEdit
          open={openEdit}
          handleClose={handleCloseEdit}
          data={dataEdit}
        />
      )}
      <CapitalAdd open={open} handleClose={handleClose} />
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <Button
            color="primary"
            className="customButton"
            variant="contained"
            onClick={handleOpen}
            size="small"
          >
            Thêm mới
          </Button>
          {/* <Button */}
          {/*  color="primary" */}
          {/*  className="customButton" */}
          {/*  variant="contained" */}
          {/*  onClick={handleOpen2} */}
          {/* > */}
          {/*  Form */}
          {/* </Button> */}
          <Box sx={{ pt: 3 }}>
            {loading ? (
              <CustomLinearProgress />
            ) : (
              <CustomTable
                title={
                  <Typography variant="h2">
                    Hợp đồng góp vốn cửa hàng
                  </Typography>
                }
                data={list}
                options={options}
                columns={columns}
                id="hop-dong-von"
              />
            )}
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Capital;
