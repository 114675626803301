import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import {
  AppBar,
  Badge,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  Tooltip
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import NotificationsIcon from "@material-ui/icons/NotificationsOutlined";
import AlarmOutlinedIcon from "@mui/icons-material/AlarmOutlined";
import InputIcon from "@material-ui/icons/Input";
import { useNavigate } from "react-router";
import Logo from "./Logo";
import useAuth from "../hooks/useAuth";
import { getListContractDelay } from "../store/slices/procedureSlice";
import { useAppDispatch } from "../hooks";

const DashboardNavbar = ({
  onMobileNavOpen,
  listOnTime = [],
  listDelay = [],
  ...rest
}) => {
  const { logout } = useAuth();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const logoutHandle = async () => {
    await logout();
    navigate("/login");
  };
  const handleOnTime = () => {
    navigate("/app/tra-gop?status=ontime");
  };
  const handleDelay = () => {
    dispatch(getListContractDelay());
    navigate("/app/tra-gop?status=delay");
  };
  return (
    <AppBar elevation={0} {...rest}>
      <Toolbar>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
        <Box sx={{ flexGrow: 1 }} />
        <Hidden lgDown>
          <IconButton
            sx={{
              display: "none"
            }}
            color="inherit"
            onClick={() => {
              handleOnTime();
            }}
          >
            <Badge badgeContent={listOnTime.length} color="notification">
              <Tooltip
                title={`Có ${listOnTime.length} hợp đồng`}
                placement="bottom"
              >
                <NotificationsIcon />
              </Tooltip>
            </Badge>
          </IconButton>
          <IconButton
            color="inherit"
            onClick={() => {
              handleDelay();
            }}
          >
            <Badge badgeContent={listDelay.length} color="notification">
              <Tooltip
                title={`Có ${listDelay.length} hợp đồng trả góp cần xử lý`}
                placement="bottom"
              >
                <AlarmOutlinedIcon />
              </Tooltip>
            </Badge>
          </IconButton>
          <IconButton color="inherit" onClick={logoutHandle}>
            <InputIcon />
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

DashboardNavbar.propTypes = {
  onMobileNavOpen: PropTypes.func,
  listOnTime: PropTypes.array,
  listDelay: PropTypes.array
};

export default DashboardNavbar;
