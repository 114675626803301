import * as React from "react";
import { useEffect } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip
} from "@material-ui/core";
import { Formik } from "formik";
import SendIcon from "@mui/icons-material/Send";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import PropTypes from "prop-types";
import PriceChangeIcon from "@material-ui/icons/PriceChangeOutlined";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import useProcedureManagement from "../hooks/useProcedureManagement";
import { contractRotate } from "../validations/contract";

const ContractDetail = ({ open, handleClose, data, tab }) => {
  const [value, setValue] = React.useState(tab);
  const {
    loading,
    // loadingDetail,
    contractDetail,
    contract,
    getListTransactions,
    listTrans = [],
    updateListTrans,
    updateTransaction,
    contractClose,
    contractTransform
    // handleCloseCat
  } = useProcedureManagement();
  useEffect(() => {
    contractDetail(data.id);
    getListTransactions(data.id);
  }, [open, data.id]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const ratio = ((contract.loanAmount * 10) / contract.receiveAmount).toFixed(
    0
  );
  const fromTime = dayjs(contract.date).format("DD/MM/YYYY");
  const toTime = dayjs(contract.date)
    .add(contract.numberPeriod, "day")
    .format("DD/MM/YYYY");
  const status = {
    closed: "Đóng hợp đồng",
    ontime: "Đến ngày đóng họ",
    pending: "Đang vay",
    late: "Chậm họ",
    expired: "Quá hạn",
    lastday: "Ngày cuối họ",
    deleted: "Đã xóa"
  };
  return (
    <>
      <div>
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
          <DialogTitle
            sx={{ fontSize: 20, textTransform: "uppercase", color: "white" }}
            className="bg-primary"
          >
            Bảng chi tiết Hợp đồng Trả Góp{" "}
            {contract && contract.id ? `BH-${contract.id}` : ``}
          </DialogTitle>
          <>
            {loading ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress color="secondary" />
              </div>
            ) : (
              <DialogContent>
                <Grid container spacing={2} sx={{ mt: 1 }}>
                  <Grid item xs={6}>
                    <TableContainer component={Paper}>
                      <Table
                        sx={{
                          "& .MuiTableRow-root:hover": {
                            backgroundColor: "table.hover"
                          },
                          "& .MuiTableCell-root": {
                            borderLeft:
                              "1px solid rgba(224, 224, 224, 1)!important"
                          }
                        }}
                        aria-label="simple table"
                      >
                        <TableBody>
                          <TableRow>
                            <TableCell component="th" scope="row" colSpan={3}>
                              {contract.customerName}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Trả Góp
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              align="right"
                              colSpan={3}
                            >
                              {contract && contract.loanAmount
                                ? contract.loanAmount.toLocaleString("en-US")
                                : 0}{" "}
                              VNĐ
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Tỷ lệ
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              align="right"
                              colSpan={3}
                            >
                              {ratio} ăn 10
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Họ từ ngày
                            </TableCell>
                            <TableCell component="th" scope="row" align="right">
                              {fromTime}
                            </TableCell>
                            <TableCell component="th" scope="row" align="right">
                              {toTime}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Nợ cũ KH:
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              align="right"
                              colSpan={3}
                            >
                              0 VNĐ
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Nợ cũ HĐ:
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              align="right"
                              colSpan={3}
                            >
                              0 VNĐ
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                  <Grid item xs={6}>
                    <TableContainer component={Paper}>
                      <Table
                        sx={{
                          "& .MuiTableRow-root:hover": {
                            backgroundColor: "table.hover"
                          },
                          "& .MuiTableCell-root": {
                            borderLeft:
                              "1px solid rgba(224, 224, 224, 1)!important"
                          }
                        }}
                        aria-label="simple table"
                      >
                        <TableBody>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Số tiền giao khách
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              align="right"
                              colSpan={2}
                            >
                              {contract && contract.receiveAmount
                                ? contract.receiveAmount.toLocaleString("en-US")
                                : 0}{" "}
                              VNĐ
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Tổng tiền phải đóng
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              align="right"
                              colSpan={2}
                            >
                              {contract && contract.loanAmount
                                ? contract.loanAmount.toLocaleString("en-US")
                                : 0}{" "}
                              VNĐ
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Đã đóng được
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              align="right"
                              colSpan={2}
                            >
                              {(contract.paidAmount || 0).toLocaleString(
                                "en-US"
                              )}
                              VNĐ
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Còn lại phải đóng
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              align="right"
                              colSpan={2}
                            >
                              {(contract.unpaidAmount || 0).toLocaleString(
                                "en-US"
                              )}
                              VNĐ
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Tổng lãi
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              align="right"
                              colSpan={2}
                            >
                              {contract &&
                              contract.receiveAmount &&
                              contract.loanAmount
                                ? (
                                    contract.loanAmount - contract.receiveAmount
                                  ).toLocaleString("en-US")
                                : 0}{" "}
                              VNĐ
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Trạng thái
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              align="right"
                              colSpan={2}
                            >
                              {contract.status ? status[contract.status] : ""}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
                <Box sx={{ width: "100%", typography: "body1" }}>
                  <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <TabList
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                      >
                        <Tab
                          label="Lịch sử đóng tiền"
                          value="1"
                          icon={<PriceChangeIcon />}
                          iconPosition="start"
                        />
                        <Tab
                          label="Đóng HĐ"
                          value="2"
                          icon={<PriceChangeIcon />}
                          iconPosition="start"
                        />
                        <Tab
                          label="Đảo họ"
                          value="3"
                          icon={<PriceChangeIcon />}
                          iconPosition="start"
                        />
                      </TabList>
                    </Box>
                    <TabPanel value="1" sx={{ p: 0, mt: 1 }}>
                      <TableContainer component={Paper}>
                        <Table
                          aria-label="simple table"
                          sx={{
                            "& .MuiTableRow-root:hover": {
                              backgroundColor: "table.hover"
                            },
                            "& .MuiTableCell-root": {
                              borderLeft:
                                "1px solid rgba(224, 224, 224, 1)!important"
                            }
                          }}
                        >
                          <TableHead>
                            <TableRow
                              sx={{
                                backgroundColor: "#f4f3f7"
                              }}
                            >
                              <TableCell>STT</TableCell>
                              <TableCell align="right">Ngày</TableCell>
                              <TableCell align="right">Tiền họ</TableCell>
                              <TableCell align="right">
                                Ngày giao dịch
                              </TableCell>
                              <TableCell align="right">
                                Tiền khách trả
                              </TableCell>
                              <TableCell align="right">Chức năng</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {listTrans.map((row, index) => (
                              <TableRow key={row.id}>
                                <TableCell component="th" scope="row">
                                  {index + 1}
                                </TableCell>
                                <TableCell align="right">
                                  {dayjs(row.date).format("DD/MM/YYYY")}
                                </TableCell>
                                <TableCell align="right">
                                  {row.expectedAmount.toLocaleString("en-US")}
                                </TableCell>
                                <TableCell align="right">
                                  {row.isPaid ? (
                                    <div>
                                      {dayjs(row.dateTransfer).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </div>
                                  ) : (
                                    <DemoContainer
                                      components={["DatePicker"]}
                                      sx={{
                                        justifyContent: "end"
                                      }}
                                    >
                                      <DatePicker
                                        size="small"
                                        format="DD/MM/YYYY"
                                        sx={{
                                          "& input": {
                                            padding: "8.5px 14px"
                                          }
                                        }}
                                        value={
                                          row.dateTransfer
                                            ? dayjs(row.dateTransfer)
                                            : dayjs()
                                        }
                                        onChange={date => {
                                          updateListTrans(
                                            row.id,
                                            "dateTransfer",
                                            date
                                          );
                                        }}
                                      />
                                    </DemoContainer>
                                  )}
                                </TableCell>
                                <TableCell align="right">
                                  {row.isPaid ? (
                                    <div>
                                      {row.amount.toLocaleString("en-US")}
                                    </div>
                                  ) : (
                                    <TextField
                                      size="small"
                                      margin="normal"
                                      value={
                                        row.amount === 0
                                          ? row.expectedAmount.toLocaleString(
                                              "en-US"
                                            )
                                          : row.amount.toLocaleString("en-US")
                                      }
                                      type="text"
                                      fullWidth
                                      variant="outlined"
                                      onChange={event => {
                                        updateListTrans(
                                          row.id,
                                          "amount",
                                          event.target.value
                                        );
                                      }}
                                    />
                                  )}
                                </TableCell>
                                <TableCell align="right">
                                  <Tooltip
                                    title={
                                      row.isPaid
                                        ? "Hủy đã thu tiền"
                                        : "Đánh dấu đã thu tiền"
                                    }
                                    placement="top"
                                  >
                                    <Checkbox
                                      {...label}
                                      defaultChecked={row.isPaid}
                                      onChange={event => {
                                        const dateTransfer = row.dateTransfer
                                          ? row.dateTransfer
                                          : row.date;
                                        const updateData = {
                                          isPaid: event.target.checked,
                                          amount:
                                            row.amount === 0
                                              ? row.expectedAmount
                                              : row.amount,
                                          dateTransfer: dayjs(
                                            dateTransfer
                                          ).format("YYYY-MM-DD")
                                        };
                                        updateTransaction(
                                          row.contractId,
                                          row.id,
                                          updateData
                                        );
                                      }}
                                    />
                                  </Tooltip>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </TabPanel>
                    <TabPanel value="2">
                      <Box>Chú ý:</Box>
                      <Box className="mb-3">
                        Số kỳ còn phải đóng :{" "}
                        {contract && contract.paidAmount && contract.loanAmount
                          ? (
                              contract.loanAmount - contract.paidAmount
                            ).toLocaleString("en-US")
                          : 0}{" "}
                        VNĐ
                      </Box>
                      {/* <Box>Nợ cũ : 0 VNĐ</Box> */}
                      <Button
                        autoFocus
                        variant="contained"
                        onClick={async () => {
                          await contractClose(contract.id, handleClose);
                        }}
                        startIcon={<SendIcon />}
                      >
                        Đóng hợp đồng
                      </Button>
                    </TabPanel>
                    <TabPanel value="3">
                      <Formik
                        initialValues={{
                          date: dayjs(),
                          loanAmount: contract.loanAmount,
                          numberPeriod: contract.numberPeriod,
                          receiveAmount: contract.receiveAmount,
                          duration: 1
                        }}
                        validationSchema={contractRotate()}
                        onSubmit={async values => {
                          console.log(contract);
                          await contractTransform(
                            values,
                            contract,
                            handleClose
                          );
                          // handleClose();
                        }}
                      >
                        {({
                          errors,
                          touched,
                          isSubmitting,
                          values,
                          handleSubmit,
                          setFieldValue
                        }) => {
                          const loanAmount = parseInt(values.loanAmount) || 0;
                          const numberPeriod =
                            parseInt(values.numberPeriod) || 1;
                          const totalMoney = loanAmount / numberPeriod;
                          const receiveAmount =
                            parseInt(values.receiveAmount) || 0;
                          const contractLoanAmount =
                            parseInt(contract.loanAmount) || 0;
                          const contractPaidAmount =
                            parseInt(contract.paidAmount) || 0;
                          const contractUnPaidAmount =
                            contractLoanAmount - contractPaidAmount;
                          const receiveAmountText =
                            receiveAmount - contractUnPaidAmount;
                          return (
                            <>
                              <Grid
                                container
                                spacing={0}
                                direction="row"
                                alignItems="center"
                                justifyContent="center"
                              >
                                <Grid container spacing={2}>
                                  <Grid item sm={12} md={2} />
                                  <Grid item sm={12} md={6}>
                                    <DemoContainer components={["DatePicker"]}>
                                      <DatePicker
                                        format="DD/MM/YYYY"
                                        label="Ngày đảo họ"
                                        size="small"
                                        id="date"
                                        value={values.date}
                                        onChange={newValue => {
                                          setFieldValue(
                                            "date",
                                            newValue !== null
                                              ? newValue
                                              : values.date
                                          );
                                        }}
                                        sx={{
                                          "& input": {
                                            padding: "8.5px 14px"
                                          }
                                        }}
                                      />
                                    </DemoContainer>
                                  </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                  <Grid item sm={12} md={2} />
                                  <Grid item sm={12} md={4}>
                                    <TextField
                                      error={Boolean(
                                        touched.loanAmount && errors.loanAmount
                                      )}
                                      helperText={
                                        touched.loanAmount && errors.loanAmount
                                      }
                                      onChange={event => {
                                        let newValue = event.target.value;
                                        newValue =
                                          parseInt(
                                            newValue.replace(/[^\d\\.]/g, "")
                                          ) || "";
                                        setFieldValue("loanAmount", newValue);
                                      }}
                                      size="small"
                                      margin="normal"
                                      id="loanAmount"
                                      value={
                                        values.loanAmount.toLocaleString(
                                          "en-US"
                                        ) || 0
                                      }
                                      label="Trả Góp mới"
                                      type="text"
                                      fullWidth
                                      variant="outlined"
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            VNĐ
                                          </InputAdornment>
                                        )
                                      }}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    sm={12}
                                    md={6}
                                    alignItems="center"
                                    container
                                  >
                                    <Box
                                      sx={{
                                        color: "text.warning"
                                      }}
                                    >
                                      (Chú ý: Trả Góp nghĩa là tổng tiền khách
                                      phải đóng)
                                    </Box>
                                  </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                  <Grid item sm={12} md={2} />
                                  <Grid item sm={12} md={4}>
                                    <TextField
                                      error={Boolean(
                                        touched.receiveAmount &&
                                          errors.receiveAmount
                                      )}
                                      helperText={
                                        touched.receiveAmount &&
                                        errors.receiveAmount
                                      }
                                      onChange={event => {
                                        let newValue = event.target.value;
                                        newValue =
                                          parseInt(
                                            newValue.replace(/[^\d\\.]/g, "")
                                          ) || "";
                                        setFieldValue(
                                          "receiveAmount",
                                          newValue
                                        );
                                      }}
                                      size="small"
                                      margin="normal"
                                      id="receiveAmount"
                                      value={
                                        values.receiveAmount.toLocaleString(
                                          "en-US"
                                        ) || 0
                                      }
                                      label="Tiền đưa khách"
                                      type="text"
                                      fullWidth
                                      variant="outlined"
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            VNĐ
                                          </InputAdornment>
                                        )
                                      }}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    sm={12}
                                    md={6}
                                    alignItems="center"
                                    container
                                  >
                                    <Box>
                                      (Đừng trừ tiền còn phải đóng của bát cũ
                                      vào đây. Hệ thống trừ sau)
                                    </Box>
                                  </Grid>
                                </Grid>

                                <Grid container spacing={2}>
                                  <Grid item sm={12} md={2} />
                                  <Grid item sm={12} md={4}>
                                    <TextField
                                      error={Boolean(
                                        touched.numberPeriod &&
                                          errors.numberPeriod
                                      )}
                                      helperText={
                                        touched.numberPeriod &&
                                        errors.numberPeriod
                                      }
                                      onChange={event => {
                                        setFieldValue(
                                          "numberPeriod",
                                          parseInt(event.target.value) || ""
                                        );
                                      }}
                                      size="small"
                                      margin="normal"
                                      id="numberPeriod"
                                      value={values.numberPeriod}
                                      label="Bốc trong vòng"
                                      type="text"
                                      fullWidth
                                      variant="outlined"
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            Ngày
                                          </InputAdornment>
                                        )
                                      }}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    sm={12}
                                    md={6}
                                    alignItems="center"
                                    container
                                  >
                                    <ArrowForwardOutlinedIcon />(
                                    <Box
                                      sx={{
                                        color: "text.warning",
                                        px: 1
                                      }}
                                    >
                                      {totalMoney.toLocaleString("en-US") || 0}{" "}
                                      VNĐ
                                    </Box>
                                    <Box
                                      sx={{
                                        pr: 1
                                      }}
                                    >
                                      / 1 ngày
                                    </Box>
                                    )
                                  </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                  <Grid item sm={12} md={2} />
                                  <Grid item sm={12} md={4}>
                                    <TextField
                                      error={Boolean(
                                        touched.duration && errors.duration
                                      )}
                                      helperText={
                                        touched.duration && errors.duration
                                      }
                                      onChange={event => {
                                        setFieldValue(
                                          "duration",
                                          parseInt(event.target.value) || ""
                                        );
                                      }}
                                      size="small"
                                      margin="normal"
                                      id="duration"
                                      value={values.duration}
                                      label="Số ngày đóng tiền"
                                      type="text"
                                      fullWidth
                                      variant="outlined"
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    sm={12}
                                    md={6}
                                    alignItems="center"
                                    container
                                  >
                                    <span>
                                      (VD : 3 ngày đóng 1 lần thì điền số 3 )
                                    </span>
                                  </Grid>
                                </Grid>
                                <Grid container spacing={2} sx={{ mt: 1 }}>
                                  <Grid item sm={12} md={2} />
                                  <Grid item sm={12} md={8}>
                                    <Box
                                      sx={{
                                        color: "text.warning"
                                      }}
                                    >
                                      Tiền khách nhận về:{" "}
                                      {receiveAmount.toLocaleString("en-US")} -{" "}
                                      {contractUnPaidAmount.toLocaleString(
                                        "en-US"
                                      )}{" "}
                                      ={" "}
                                      {receiveAmountText.toLocaleString(
                                        "en-US"
                                      ) || 0}
                                    </Box>
                                  </Grid>
                                </Grid>
                                <Grid container spacing={2} sx={{ mt: 1 }}>
                                  <Grid item sm={12} md={2} />
                                  <Grid item sm={12} md={6}>
                                    <Button
                                      autoFocus
                                      variant="contained"
                                      disabled={isSubmitting}
                                      onClick={handleSubmit}
                                      startIcon={<SendIcon />}
                                    >
                                      Đảo họ
                                    </Button>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </>
                          );
                        }}
                      </Formik>
                    </TabPanel>
                  </TabContext>
                </Box>
              </DialogContent>
            )}
          </>

          <DialogActions
            sx={{
              borderTop: "1px solid rgba(224, 224, 224, 1)"
            }}
          >
            <Button onClick={handleClose}>Ẩn</Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

ContractDetail.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  // eslint-disable-next-line react/no-unused-prop-types
  data: PropTypes.object,
  tab: PropTypes.string
};

export default ContractDetail;
