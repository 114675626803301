import { Avatar, Card, CardContent, Grid, Typography } from "@material-ui/core";
import PeopleIcon from "@material-ui/icons/People";
import { green } from "@material-ui/core/colors";
import PropTypes from "prop-types";

const TotalContract = ({ statistic, ...props }) => (
  <Card sx={{ height: "100%" }} {...props}>
    <CardContent>
      <Grid container spacing={3} sx={{ justifyContent: "space-between" }}>
        <Grid item>
          <Typography color="textSecondary" gutterBottom variant="h6">
            SỐ HỢP ĐỒNG ĐANG VAY
          </Typography>
          <Typography color="textPrimary" variant="h3">
            {statistic.contractActive} / {statistic.totalContract}
          </Typography>
        </Grid>
        <Grid item>
          <Avatar
            sx={{
              backgroundColor: green[600],
              height: 56,
              width: 56
            }}
          >
            <PeopleIcon />
          </Avatar>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);

TotalContract.propTypes = {
  statistic: PropTypes.object
};
export default TotalContract;
