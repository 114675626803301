import { useState } from "react";
import { toast } from "react-toastify";
import {
  createNewCapital,
  deleteCapital,
  editCapital,
  getCapitalDetail,
  getCapitalList
} from "../store/slices/capitalSlice";
import { useAppDispatch, useAppSelector } from "./index";
// import { getCapitalCategoriesList } from "../store/slices/capitalCategorySlice";

const useCapitalManagement = () => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({});
  const [title, setTitle] = useState("Thêm hợp đồng");
  const [loading, setLoading] = useState(false);
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(false);
  const [deleteModalContent, setDeleteModalContent] = useState("");
  const [checked, setChecked] = useState({});
  const [dataEdit, setDataEdit] = useState({});
  const [openEdit, setOpenEdit] = useState(false);
  const handleChecked = event => {
    setChecked(event.target.checked);
  };

  const { list, capital } = useAppSelector(state => state.capital);

  const dispatch = useAppDispatch();

  const capitalDetail = async id => {
    setLoadingDetail(true);
    try {
      await dispatch(getCapitalDetail(id));
      Promise.resolve();
    } finally {
      setLoading(false);
    }
  };

  const getList = async () => {
    setLoading(true);
    try {
      await dispatch(getCapitalList());
      Promise.resolve();
    } finally {
      setLoading(false);
    }
  };

  const createCapital = async (capitalData, actions, handleClose) => {
    try {
      const res = await dispatch(createNewCapital(capitalData));
      const success = !res.payload?.error;
      actions.setSubmitting(false);
      if (!success) {
        toast.error(res.payload?.error?.message);
      } else {
        toast.success("Thêm góp vốn thành công");
        await dispatch(getCapitalList());
      }
      Promise.resolve();
      handleClose();
    } finally {
      setOpen(false);
    }
  };

  const edit = async (capitalData, actions) => {
    try {
      const res = await dispatch(editCapital(capitalData));
      const success = !res.payload?.error;
      if (!success) {
        // actions.setErrors(errors);
        actions.setSubmitting(true);
        toast.error(res.payload?.error?.message);
      } else {
        setOpen(false);
        await dispatch(getCapitalListByClient());
        toast.success(" Cập nhật thành công");
      }
      actions.setSubmitting(false);
      Promise.resolve();
    } finally {
      // setOpen(false);
    }
  };

  const remove = async capitalId => {
    if (!capitalId) return;

    try {
      await dispatch(deleteCapital(capitalId));
      await dispatch(getCapitalList());
      toast.success("Xóa thành công");
    } finally {
      setOpenModalDelete(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseModalDelete = () => {
    setOpenModalDelete(false);
  };

  const handleOpenModalDelete = (content, capitalId) => {
    setOpenModalDelete(true);
    setDeleteId(capitalId);
    setDeleteModalContent(content);
  };

  const handleOpen = (dataCapital = {}, titleText = title) => {
    setOpen(true);
    setData(dataCapital);
    setTitle(titleText);
  };

  const handleOpenEdit = (editData = {}) => {
    setOpenEdit(true);
    setDataEdit(editData);
  };
  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  return {
    open,
    setOpen,
    handleClose,
    handleOpen,
    data,
    createCapital,
    getList,
    remove,
    edit,
    loading,
    list,
    title,
    openModalDelete,
    handleCloseModalDelete,
    handleOpenModalDelete,
    deleteModalContent,
    deleteId,
    checked,
    handleChecked,
    capitalDetail,
    capital,
    loadingDetail,
    handleOpenEdit,
    handleCloseEdit,
    openEdit,
    dataEdit
  };
};

export default useCapitalManagement;
