import httpClient from ".";

export const API_PROCEDURE_PATH = "contract";
export const API_PROCEDURE_CATEGORY_PATH = "procedure/category";

export const getAllProcedures = data =>
  httpClient.get(`${API_PROCEDURE_PATH}/list`, data);

export const getProceduresByClient = filter =>
  httpClient.get(`${API_PROCEDURE_PATH}${filter}`);

export const getProceduresMasseClient = clientId =>
  httpClient.get(`${API_PROCEDURE_PATH}/client/${clientId}`);

export const getAllProcedureCategories = data =>
  httpClient.get(`${API_PROCEDURE_PATH}/categories`, data);

export const create = data => httpClient.post(API_PROCEDURE_PATH, data);
export const createCategory = data =>
  httpClient.post(API_PROCEDURE_CATEGORY_PATH, data);

export const edit = ({ id, ...data }) =>
  httpClient.put(`${API_PROCEDURE_PATH}/${id}`, data);

export const editCat = ({ id_cat: id, ...data }) =>
  httpClient.put(`${API_PROCEDURE_CATEGORY_PATH}/${id}`, data);

export const remove = id => httpClient.delete(`${API_PROCEDURE_PATH}/${id}`);

export const detail = contractId =>
  httpClient.get(`${API_PROCEDURE_PATH}/${contractId}`);
export const removeCategory = id =>
  httpClient.delete(`${API_PROCEDURE_CATEGORY_PATH}/${id}`);

export const updateMasse = data =>
  httpClient.post(`${API_PROCEDURE_PATH}/update-masse-procedure`, data);
export const contractPayFast = ({ contractId, data }) =>
  httpClient.post(`${API_PROCEDURE_PATH}/${contractId}/pay-fast`, data);
export const contractTransform = (contractId, data) =>
  httpClient.post(`${API_PROCEDURE_PATH}/${contractId}/change-contract`, data);
