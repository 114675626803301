import { useState } from "react";
import { toast } from "react-toastify";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import {
  createNewProcedure,
  createNewProcedureCat,
  deleteProcedure,
  deleteProcedureCategory,
  editProcedure,
  editProcedureCat,
  getContractDetail,
  getProcedureList,
  getProcedureListByClient,
  getListContractOnTime,
  getProcedureListMasseClient,
  setMasseList,
  updateMasseProcedure,
  listTransactions,
  setListTrans,
  updateTransactionSlice,
  closeContract,
  contractPayFastSlice,
  getListContractDelay,
  contractTransformSlice,
  setDelayList
} from "../store/slices/procedureSlice";
import { useAppDispatch, useAppSelector } from "./index";

// import { getProcedureCategoriesList } from "../store/slices/procedureCategorySlice";

const useProcedureManagement = () => {
  const [open, setOpen] = useState(false);
  const [openCat, setOpenCat] = useState(false);
  const [openMasse, setOpenMasse] = useState(false);
  const [data, setData] = useState({});
  const [dataCat, setDataCat] = useState({});
  const [tab, setTab] = useState("1");
  const [loading, setLoading] = useState(false);
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(false);
  const [deleteModalContent, setDeleteModalContent] = useState("");
  const [checked, setChecked] = useState({});
  const [dataEdit, setDataEdit] = useState({});
  const [openEdit, setOpenEdit] = useState(false);
  const location = useLocation();
  const handleChecked = event => {
    setChecked(event.target.checked);
  };

  const {
    list,
    listByClient,
    listMasseClient,
    contract,
    listTrans,
    listOnTime,
    listDelay
  } = useAppSelector(state => state.procedure);

  const { list: procedureCategories = [] } = useAppSelector(
    state => state.procedureCategory
  );
  const dispatch = useAppDispatch();

  const contractDetail = async id => {
    setLoadingDetail(true);
    try {
      await dispatch(getContractDetail(id));
    } finally {
      setLoadingDetail(false);
    }
  };
  const updateTransaction = async (contractId, transactionId, dataUpdate) => {
    // setLoadingDetail(true);
    try {
      const newData = {
        contractId,
        transactionId,
        data: dataUpdate
      };
      const message = dataUpdate.isPaid
        ? "Trả tiền thành công"
        : "Hủy trả tiền thành công";
      const res = await dispatch(updateTransactionSlice(newData));
      const success = !res.payload?.error;
      if (!success) {
        toast.error(res.payload?.error?.message);
        // await dispatch(listTransactions(contractId));
      } else {
        toast.success(message);
        await dispatch(getContractDetail(contractId));
        await dispatch(listTransactions(contractId));
        const params = queryString.parse(location.search);
        const isDelay = params && params.status === "delay";
        if (isDelay) {
          const filter = "?status=delay";
          await dispatch(getProcedureListByClient(filter));
          await dispatch(getListContractDelay());
        } else {
          await dispatch(getProcedureListByClient());
        }
      }
    } finally {
      // setLoading(false);
    }
  };

  const updateListTrans = (id, field, newValue) => {
    const newArray = JSON.parse(JSON.stringify(listTrans));
    const findIndex = newArray.findIndex(item => item.id === id);
    if (field === "amount") {
      newValue = parseInt(newValue.replace(/[^\d\\.]/g, "")) || "";
    }
    newArray[findIndex][field] = newValue;
    dispatch(setListTrans(newArray));
  };
  const contractPayFast = async (contractId, dataUpdate) => {
    // setLoadingDetail(true);
    try {
      const newData = {
        contractId,
        data: dataUpdate
      };
      const res = await dispatch(contractPayFastSlice(newData));
      const success = !res.payload?.error;
      if (!success) {
        toast.error(res.payload?.error?.message);
        // await dispatch(listTransactions(contractId));
      } else {
        toast.success("Trả tiền nhanh thành công");
        // await dispatch(getContractDetail(contractId));
        // await dispatch(listTransactions(contractId));
        // dispatch(getProcedureListByClient());
      }
    } finally {
      // setLoading(false);
    }
  };

  const getListTransactions = async contractId => {
    setLoading(true);
    try {
      await dispatch(listTransactions(contractId));
    } finally {
      setLoading(false);
    }
  };
  const getProcedureCategories = async () => {
    // setLoading(true);
    try {
      await dispatch(getProcedureCategoriesList());
    } finally {
      // setLoading(false);
    }
  };

  const getList = async () => {
    setLoading(true);
    try {
      await dispatch(getProcedureList());
    } finally {
      setLoading(false);
    }
  };

  const getListByClient = async (filter = "") => {
    setLoading(true);
    try {
      await dispatch(getProcedureListByClient(filter));
    } finally {
      setLoading(false);
    }
  };

  const getContractOnTime = async () => {
    // setLoading(true);
    try {
      await dispatch(getListContractOnTime());
    } finally {
      // setLoading(false);
    }
  };
  const getContractDelay = async () => {
    // setLoading(true);
    try {
      await dispatch(getListContractDelay());
    } finally {
      // setLoading(false);
    }
  };

  const getListMasseClient = async clientId => {
    setLoading(true);
    try {
      return await dispatch(getProcedureListMasseClient(clientId));
    } finally {
      setLoading(false);
    }
  };

  const createProcedure = async (procedureData, actions) => {
    try {
      const res = await dispatch(createNewProcedure(procedureData));
      const success = !res.payload?.error;
      if (!success) {
        // actions.setErrors(errors);
        actions.setSubmitting(true);
        toast.error(res.payload?.error?.message);
      } else {
        setOpen(false);
        await dispatch(getProcedureListByClient());
        toast.success("Tạo hợp đồng thành công");
      }
      actions.setSubmitting(false);
      Promise.resolve();
    } finally {
      // setOpen(false);
    }
  };

  const createProcedureCat = async (procedureCatData, actions) => {
    try {
      const res = await dispatch(createNewProcedureCat(procedureCatData));
      const { errors } = res.payload;
      if (errors && Object.keys(errors).length) {
        if (errors.frontend_url && !errors.email) {
          errors.email = errors.frontend_url;
        }
        actions.setErrors(errors);
      } else {
        setOpenCat(false);
        await dispatch(getProcedureCategoriesList());
        toast.success("Tạo hợp đồng thành công");
      }
      actions.setSubmitting(false);
      Promise.resolve();
    } finally {
      // setOpen(false);
    }
  };

  const edit = async (procedureData, actions) => {
    try {
      const res = await dispatch(editProcedure(procedureData));
      const success = !res.payload?.error;
      if (!success) {
        // actions.setErrors(errors);
        actions.setSubmitting(true);
        toast.error(res.payload?.error?.message);
      } else {
        setOpen(false);
        toast.success(" Cập nhật thành công");
        const params = queryString.parse(location.search);
        const isDelay = params && params.status === "delay";
        if (isDelay) {
          const filter = "?status=delay";
          await dispatch(getProcedureListByClient(filter));
        } else {
          await dispatch(getProcedureListByClient());
        }
      }
      actions.setSubmitting(false);
      Promise.resolve();
    } finally {
      // setOpen(false);
    }
  };

  const editCategory = async (procedureData, actions) => {
    try {
      const res = await dispatch(editProcedureCat(procedureData));

      const { errors } = res.payload;
      if (errors && Object.keys(errors).length) {
        if (errors.frontend_url && !errors.email) {
          errors.email = errors.frontend_url;
        }
        actions.setErrors(errors);
      } else {
        setOpenCat(false);
        await dispatch(getProcedureCategoriesList());
        toast.success("Thành công");
      }
      actions.setSubmitting(false);
      Promise.resolve();
    } finally {
      // setOpen(false);
    }
  };

  const remove = async procedureId => {
    if (!procedureId) return;
    try {
      const res = await dispatch(deleteProcedure(procedureId));
      const success = !res.payload?.error;
      if (!success) {
        toast.error(res.payload?.error?.message);
      } else {
        toast.success("Xóa thành công");
        const params = queryString.parse(location.search);
        const isDelay = params && params.status === "delay";
        if (isDelay) {
          const filter = "?status=delay";
          const newDayList = [...listDelay].filter(
            item => item.id !== procedureId
          );
          dispatch(setDelayList(newDayList));
          await dispatch(getProcedureListByClient(filter));
        } else {
          await dispatch(getProcedureListByClient());
        }
        // const filter = params.status ? `?status=${params.status}` : "";
      }
      Promise.resolve();
    } finally {
      setOpenModalDelete(false);
    }
  };
  const contractTransform = async (dataTransform, contractData, callback) => {
    const contractId = contractData.id;
    if (!contractId) return;
    try {
      dataTransform.customerName = contractData.customerName;
      dataTransform.period = "day";
      const res = await dispatch(
        contractTransformSlice({ contractId, data: dataTransform })
      );
      const success = !res.payload?.error;
      if (!success) {
        toast.error(res.payload?.error?.message);
      } else {
        toast.success("Đảo họ thành công");
        const newDayList = [...listDelay].filter(
          item => item.id !== contractId
        );
        dispatch(setDelayList(newDayList));
        const params = queryString.parse(location.search);
        const isDelay = params && params.status === "delay";
        if (isDelay) {
          const filter = "?status=delay";
          await dispatch(getProcedureListByClient(filter));
        } else {
          await dispatch(getProcedureListByClient());
        }
        callback();
      }
      Promise.resolve();
    } finally {
      // setOpenModalDelete(false);
    }
  };

  const removeCategory = async categoryId => {
    if (!categoryId) return;

    try {
      await dispatch(deleteProcedureCategory(categoryId));
      await dispatch(getProcedureCategoriesList());
      toast.success("Thành công");
    } finally {
      setOpenModalDelete(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseCat = () => {
    setOpenCat(false);
  };
  const contractClose = async (contractId, callback) => {
    if (!contractId) return;
    try {
      const res = await dispatch(
        closeContract({
          id: contractId,
          status: "closed"
        })
      );
      const success = !res.payload?.error;
      if (!success) {
        toast.error(res.payload?.error?.message);
        // await dispatch(listTransactions(contractId));
      } else {
        toast.success("Đóng hợp đồng thành công");
        const params = queryString.parse(location.search);
        const isDelay = params && params.status === "delay";
        if (isDelay) {
          const newDayList = [...listDelay].filter(
            item => item.id !== contractId
          );
          dispatch(setDelayList(newDayList));
          const filter = "?status=delay";
          await dispatch(getProcedureListByClient(filter));
        } else {
          await dispatch(getProcedureListByClient());
        }
      }
      callback();
      Promise.resolve();
    } finally {
      setOpenCat(false);
    }
  };
  const handleCloseModalDelete = () => {
    setOpenModalDelete(false);
  };

  const handleOpenModalDelete = (content, procedureId) => {
    setOpenModalDelete(true);
    setDeleteId(procedureId);
    setDeleteModalContent(content);
  };

  const handleOpen = (dataProcedure = {}, activeTab = tab) => {
    setOpen(true);
    setData(dataProcedure);
    setTab(activeTab);
  };

  const handleOpenCat = (dataProcedureCat = {}, activeTab = tab) => {
    setOpenCat(true);
    setDataCat(dataProcedureCat);
    setTab(activeTab);
  };

  const handleOpenEdit = (editData = {}) => {
    setOpenEdit(true);
    setDataEdit(editData);
  };
  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleOpenMasse = () => {
    setOpenMasse(true);
  };

  const handleCloseMasse = () => {
    setOpenMasse(false);
  };

  const updateCheckProcedure = async dataProcedure => {
    await dispatch(setMasseList(dataProcedure));
  };

  const submitMasseProcedure = async (clientId, listMasseProcedure) => {
    if (clientId) {
      await dispatch(updateMasseProcedure({ clientId, listMasseProcedure }));
      toast.success("Lưu thành công");
    } else {
      toast.error("Lưu lỗi!");
    }
  };

  return {
    open,
    setOpen,
    handleClose,
    handleOpen,
    data,
    createProcedure,
    getList,
    remove,
    edit,
    loading,
    list,
    openModalDelete,
    handleCloseModalDelete,
    handleOpenModalDelete,
    deleteModalContent,
    deleteId,
    checked,
    handleChecked,
    procedureCategories,
    getProcedureCategories,
    getListByClient,
    listByClient,
    openMasse,
    setOpenMasse,
    handleOpenMasse,
    handleCloseMasse,
    getListMasseClient,
    listMasseClient,
    updateCheckProcedure,
    submitMasseProcedure,
    removeCategory,
    handleOpenCat,
    openCat,
    setOpenCat,
    handleCloseCat,
    dataCat,
    createProcedureCat,
    contractDetail,
    editCategory,
    contract,
    getListTransactions,
    listTrans,
    loadingDetail,
    updateListTrans,
    updateTransaction,
    handleOpenEdit,
    handleCloseEdit,
    openEdit,
    dataEdit,
    tab,
    contractClose,
    listOnTime,
    listDelay,
    getContractOnTime,
    getContractDelay,
    contractPayFast,
    contractTransform
  };
};

export default useProcedureManagement;
