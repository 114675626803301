import "react-perfect-scrollbar/dist/css/styles.css";
import { useNavigate, useRoutes } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core";
import GlobalStyles from "src/components/GlobalStyles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ToastContainer } from "react-toastify";
import { useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import { createBrowserHistory } from "history";
import "src/mixins/chartjs";
import theme from "src/theme";
import routes from "src/routes";
import useAuth from "./hooks/useAuth";

const history = createBrowserHistory();
const App = () => {
  const { isAuthenticated } = useAuth();

  const routing = useRoutes(routes(isAuthenticated));
  const navigate = useNavigate();
  const historyChange = () => {
    if (window.location.pathname !== history.location.pathname) {
      navigate(window.location.pathname, { replace: true });
    }
  };
  useEffect(() => {
    history.listen(historyChange);
  }, [historyChange]);
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={theme}>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <GlobalStyles />
        {routing}
      </ThemeProvider>
    </LocalizationProvider>
  );
};

export default App;
