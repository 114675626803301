import httpClient from ".";

export const API_CAPITAL_PATH = "/user/capital";

export const getAll = () => httpClient.get(`${API_CAPITAL_PATH}`);

export const create = data => httpClient.post(API_CAPITAL_PATH, data);

export const edit = ({ id, ...data }) =>
  httpClient.put(`${API_CAPITAL_PATH}/${id}`, data);

export const remove = id => httpClient.delete(`${API_CAPITAL_PATH}/${id}`);

export const detail = id => httpClient.get(`${API_CAPITAL_PATH}/${id}`);
