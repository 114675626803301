import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  InputAdornment,
  TextField,
  Typography
} from "@material-ui/core";
import PropTypes from "prop-types";
// import { makeStyles } from "@material-ui/core/styles";
import { makeStyles } from "@mui/styles";
import { Formik } from "formik";
import { contractCapital } from "../validations/contract";
import useCapitalManagement from "../hooks/useCapitalManagement";
// import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiTextField-root": {
      margin: "1rem"
    }
  },
  textarea: {
    resize: "both"
  }
}));
const CapitalAdd = ({ open, handleClose }) => {
  const { createCapital } = useCapitalManagement();
  // const [textFormality, setTextFormality] = React.useState("1");

  const classes = useStyles();
  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle
          sx={{ fontSize: 20, textTransform: "uppercase", color: "white" }}
          className="bg-primary"
        >
          Thêm mới hợp đồng góp vốn
        </DialogTitle>
        <Formik
          initialValues={{
            name: "",
            amount: "",
            note: ""
          }}
          validationSchema={contractCapital()}
          onSubmit={async (values, actions) => {
            await createCapital(values, actions, handleClose);
            // handleClose();
          }}
        >
          {({
            errors,
            handleChange,
            touched,
            isSubmitting,
            values,
            handleSubmit,
            setFieldValue
          }) => (
            // const selectedTypeLend = typeLend.find(item => item.value === values.period);
            <>
              <DialogContent>
                <Typography
                  color="textPrimary"
                  variant="h4"
                  sx={{ my: 1, textTransform: "uppercase" }}
                >
                  Thông tin góp vốn
                </Typography>
                <Divider />
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                      onChange={handleChange}
                      autoFocus
                      size="small"
                      margin="normal"
                      id="name"
                      value={values.name}
                      label="Tên loại vốn"
                      required
                      type="text"
                      fullWidth
                      variant="outlined"
                    />
                    <TextField
                      error={Boolean(touched.amount && errors.amount)}
                      helperText={touched.amount && errors.amount}
                      onChange={event => {
                        let newValue = event.target.value;
                        newValue =
                          parseInt(newValue.replace(/[^\d\\.]/g, "")) || "";
                        setFieldValue("amount", newValue);
                      }}
                      size="small"
                      margin="normal"
                      id="amount"
                      value={values.amount.toLocaleString("en-US") || 0}
                      label="Số tiền"
                      type="text"
                      fullWidth
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">VNĐ</InputAdornment>
                        )
                      }}
                    />
                    <TextField
                      error={Boolean(touched.note && errors.note)}
                      helperText={touched.note && errors.note}
                      onChange={handleChange}
                      id="note"
                      value={values.note}
                      label="Ghi chú"
                      type="text"
                      fullWidth
                      multiline
                      rows={5}
                      variant="outlined"
                      inputProps={{ className: classes.textarea }}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions
                sx={{
                  borderTop: "1px solid rgba(224, 224, 224, 1)"
                }}
              >
                <Button onClick={handleClose}>Đóng</Button>
                <Button disabled={isSubmitting} onClick={handleSubmit}>
                  Lưu lại
                </Button>
              </DialogActions>
            </>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};
CapitalAdd.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func
};

export default CapitalAdd;
