import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Divider,
  Typography,
  InputAdornment,
  Box
} from "@material-ui/core";
import PropTypes from "prop-types";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { Formik } from "formik";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import dayjs from "dayjs";
import { contractCreate } from "../validations/contract";
import useProcedureManagement from "../hooks/useProcedureManagement";
import SearchDropdown from "../components/common/SearchDropdown";

const typeLend = [
  { label: "Theo ngày", value: "day", id: "day", key: 1 },
  { label: "Theo tháng", value: "month", id: "month", key: 2 }
];

const ContractAdd = ({ open, handleClose }) => {
  const { createProcedure } = useProcedureManagement();
  // const [textFormality, setTextFormality] = React.useState("1");
  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
        <DialogTitle
          sx={{ fontSize: 20, textTransform: "uppercase", color: "white" }}
          className="bg-primary"
        >
          Thêm mới hợp đồng
        </DialogTitle>
        <Formik
          initialValues={{
            isNewClient: 1,
            customerName: "",
            customerId: "",
            address: "",
            phone: "",
            loanAmount: "",
            receiveAmount: "",
            period: "day",
            numberPeriod: 60,
            duration: 1,
            note: "",
            collectMoney: false,
            date: dayjs(),
            receiver: ""
          }}
          validationSchema={contractCreate()}
          onSubmit={async (values, actions) => {
            await createProcedure(values, actions);
            handleClose();
          }}
        >
          {({
            errors,
            handleChange,
            touched,
            isSubmitting,
            values,
            handleSubmit,
            handleBlur,
            setFieldValue
          }) => {
            // const selectedTypeLend = typeLend.find(item => item.value === values.period);
            const timeUnit = values.period === "day" ? "Ngày" : "Tháng";
            const loanAmount = parseInt(values.loanAmount) || 0;
            const numberPeriod = parseInt(values.numberPeriod) || 1;
            const totalMoney = loanAmount / numberPeriod;
            return (
              <>
                <DialogContent>
                  <Typography
                    color="textPrimary"
                    variant="h4"
                    sx={{ my: 1, textTransform: "uppercase" }}
                  >
                    Thông tin khách hàng
                  </Typography>
                  <Divider />
                  <Grid container spacing={2}>
                    <Grid item sm={12} md={6}>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          id="isNewClient"
                          name="isNewClient"
                          onChange={handleChange}
                        >
                          <FormControlLabel
                            value="1"
                            control={
                              <Radio
                                checked={parseInt(values.isNewClient) === 1}
                              />
                            }
                            label="Khách hàng mới"
                          />
                          <FormControlLabel
                            value="2"
                            control={
                              <Radio
                                checked={parseInt(values.isNewClient) === 2}
                              />
                            }
                            label="Khách hàng cũ"
                          />
                        </RadioGroup>
                      </FormControl>
                      <TextField
                        error={Boolean(
                          touched.customerName && errors.customerName
                        )}
                        helperText={touched.customerName && errors.customerName}
                        onChange={handleChange}
                        autoFocus
                        size="small"
                        margin="normal"
                        id="customerName"
                        value={values.customerName}
                        label="Tên Khách hàng"
                        required
                        type="text"
                        fullWidth
                        variant="outlined"
                      />
                      <TextField
                        error={Boolean(touched.customerId && errors.customerId)}
                        helperText={touched.customerId && errors.customerId}
                        onChange={handleChange}
                        size="small"
                        margin="normal"
                        id="customerId"
                        value={values.customerId}
                        label="Số CMND/Hộ chiếu"
                        type="text"
                        fullWidth
                        variant="outlined"
                      />
                      <TextField
                        error={Boolean(touched.address && errors.address)}
                        helperText={touched.address && errors.address}
                        onChange={handleChange}
                        size="small"
                        margin="normal"
                        id="address"
                        value={values.address}
                        label="Địa chỉ"
                        type="text"
                        fullWidth
                        rows={2}
                        maxRows={4}
                        variant="outlined"
                      />
                      <TextField
                        error={Boolean(touched.phone && errors.phone)}
                        helperText={touched.phone && errors.phone}
                        onChange={handleChange}
                        size="small"
                        margin="normal"
                        id="phone"
                        value={values.phone}
                        label="Số điện thoại"
                        type="text"
                        fullWidth
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Typography
                    color="textPrimary"
                    variant="h4"
                    sx={{ my: 1, textTransform: "uppercase" }}
                  >
                    Thông tin Trả Góp
                  </Typography>
                  <Divider />
                  <Grid container spacing={2}>
                    <Grid item sm={12} md={6}>
                      <TextField
                        error={Boolean(touched.loanAmount && errors.loanAmount)}
                        helperText={touched.loanAmount && errors.loanAmount}
                        onChange={event => {
                          let newValue = event.target.value;
                          newValue =
                            parseInt(newValue.replace(/[^\d\\.]/g, "")) || "";
                          setFieldValue("loanAmount", newValue);
                        }}
                        size="small"
                        margin="normal"
                        id="loanAmount"
                        value={values.loanAmount.toLocaleString("en-US") || 0}
                        label="Trả Góp"
                        type="text"
                        fullWidth
                        variant="outlined"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">VNĐ</InputAdornment>
                          )
                        }}
                      />
                    </Grid>
                    <Grid item sm={12} md={6} alignItems="center" container>
                      <Box
                        sx={{
                          color: "text.warning"
                        }}
                      >
                        (Chú ý: Trả Góp nghĩa là tổng tiền khách phải đóng)
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item sm={12} md={6}>
                      <TextField
                        error={Boolean(
                          touched.receiveAmount && errors.receiveAmount
                        )}
                        helperText={
                          touched.receiveAmount && errors.receiveAmount
                        }
                        onChange={event => {
                          let newValue = event.target.value;
                          newValue =
                            parseInt(newValue.replace(/[^\d\\.]/g, "")) || "";
                          setFieldValue("receiveAmount", newValue);
                        }}
                        size="small"
                        margin="normal"
                        id="receiveAmount"
                        value={
                          values.receiveAmount.toLocaleString("en-US") || 0
                        }
                        label="Tiền đưa khách"
                        type="text"
                        fullWidth
                        variant="outlined"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">VNĐ</InputAdornment>
                          )
                        }}
                      />
                    </Grid>
                    <Grid item sm={12} md={6} alignItems="center" container>
                      <span>(Tổng tiền khách nhận được)</span>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item sm={12} md={6}>
                      <SearchDropdown
                        title="Hình thức"
                        data={typeLend || []}
                        error={Boolean(touched.period && errors.period)}
                        helperText={touched.period && errors.period}
                        value={values.period}
                        onBlur={handleBlur}
                        setFieldValue={setFieldValue}
                        id="period"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item sm={12} md={6}>
                      <TextField
                        error={Boolean(
                          touched.numberPeriod && errors.numberPeriod
                        )}
                        helperText={touched.numberPeriod && errors.numberPeriod}
                        onChange={event => {
                          setFieldValue(
                            "numberPeriod",
                            parseInt(event.target.value) || ""
                          );
                        }}
                        size="small"
                        margin="normal"
                        id="numberPeriod"
                        value={values.numberPeriod}
                        label="Bốc trong vòng"
                        type="text"
                        fullWidth
                        variant="outlined"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">Ngày</InputAdornment>
                          )
                        }}
                      />
                    </Grid>
                    <Grid item sm={12} md={6} alignItems="center" container>
                      <ArrowForwardOutlinedIcon />(
                      <Box
                        sx={{
                          color: "text.warning",
                          px: 1
                        }}
                      >
                        {totalMoney.toLocaleString("en-US") || 0} VNĐ
                      </Box>
                      <Box
                        sx={{
                          pr: 1
                        }}
                      >
                        / 1 {timeUnit}
                      </Box>
                      )
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item sm={12} md={6}>
                      <TextField
                        error={Boolean(touched.duration && errors.duration)}
                        helperText={touched.duration && errors.duration}
                        onChange={event => {
                          setFieldValue(
                            "duration",
                            parseInt(event.target.value) || ""
                          );
                        }}
                        size="small"
                        margin="normal"
                        id="duration"
                        value={values.duration}
                        label="Số ngày đóng tiền"
                        type="text"
                        fullWidth
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item sm={12} md={6} container alignItems="center">
                      <span>(VD : 3 ngày đóng 1 lần thì điền số 3 )</span>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item sm={12} md={6}>
                      <DemoContainer components={["DatePicker"]}>
                        <DatePicker
                          label="Ngày bốc"
                          size="small"
                          id="date"
                          format="DD/MM/YYYY"
                          value={values.date}
                          onChange={newValue => {
                            setFieldValue(
                              "date",
                              newValue !== null ? newValue : values.date
                            );
                          }}
                          sx={{
                            "& input": {
                              padding: "8.5px 14px"
                            }
                          }}
                        />
                      </DemoContainer>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item sm={12} md={6}>
                      <TextField
                        error={Boolean(touched.note && errors.note)}
                        helperText={touched.note && errors.note}
                        onChange={handleChange}
                        size="small"
                        margin="normal"
                        id="note"
                        value={values.note}
                        label="Ghi chú"
                        type="text"
                        fullWidth
                        multiline
                        rows={5}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item sm={12} md={6}>
                      <TextField
                        error={Boolean(touched.receiver && errors.receiver)}
                        helperText={touched.receiver && errors.receiver}
                        onChange={handleChange}
                        size="small"
                        margin="normal"
                        id="receiver"
                        value={values.receiver}
                        label="NV thu tiền"
                        type="text"
                        fullWidth
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item sm={12} md={6} container alignItems="center">
                      <span>(Người đi thu tiền họ cho HĐ)</span>
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions
                  sx={{
                    borderTop: "1px solid rgba(224, 224, 224, 1)"
                  }}
                >
                  <Button onClick={handleClose}>Đóng</Button>
                  <Button disabled={isSubmitting} onClick={handleSubmit}>
                    Lưu lại
                  </Button>
                </DialogActions>
              </>
            );
          }}
        </Formik>
      </Dialog>
    </div>
  );
};
ContractAdd.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func
};

export default ContractAdd;
