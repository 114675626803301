import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import common from "../../constants/common";

import {
  API_CAPITAL_PATH,
  create,
  edit,
  remove,
  detail,
  getAll
} from "../../services/capital";

const initialState = {
  list: [],
  capital: {}
};
export const getCapitalDetail = createAsyncThunk(
  `${API_CAPITAL_PATH}/${common.detail}`,
  async id => {
    const res = await detail(id);
    return res;
  }
);
export const getCapitalList = createAsyncThunk(
  `${API_CAPITAL_PATH}/${common.list}`,
  async () => {
    const res = await getAll();
    return res;
  }
);

export const createNewCapital = createAsyncThunk(
  `${API_CAPITAL_PATH}/create`,
  async capital => {
    const res = await create(capital);
    return { ...res, capital };
  }
);

export const editCapital = createAsyncThunk(
  `${API_CAPITAL_PATH}/${common.edit}`,
  async capital => {
    const res = await edit(capital);
    return { capital, res };
  }
);

export const editCapitalCat = createAsyncThunk(
  `${API_CAPITAL_PATH}/${common.edit}`,
  async capital => {
    const res = await editCat(capital);
    return { capital, res };
  }
);

export const deleteCapital = createAsyncThunk(
  `${API_CAPITAL_PATH}/${common.delete}`,
  async id => {
    await remove(id);
  }
);

export const capitalSlice = createSlice({
  name: "capital",
  initialState,
  reducers: {
    setCapital: (state, { payload }) => {
      state.capital = payload;
    },
    setCapitalList: (state, { payload }) => {
      state.list = payload;
    }
  },
  extraReducers: {
    [getCapitalList.fulfilled]: (state, { payload }) => {
      if (payload.status) {
        const { data } = payload.data;
        const sum = data.reduce(
          (total, item) => total + parseInt(item.amount),
          0
        );
        const newData = data.concat([
          {
            id: -1,
            userId: "Tổng tiền",
            type: "invest",
            name: "",
            note: "",
            amount: sum,
            createdAt: "2024-03-09T17:15:58.200Z",
            updatedAt: "2024-03-09T17:15:58.200Z"
          }
        ]);
        state.list = newData;
      }
    },
    [createNewCapital.fulfilled]: (state, { payload }) => {
      if (payload.status) {
        state.list.push(payload.capital);
      }
    },
    [getCapitalDetail.fulfilled]: (state, { payload }) => {
      if (payload.status) {
        const { data } = payload.data;
        state.capital = data;
      }
    }
  }
});

export const { setCapital, setCapitalList } = capitalSlice.actions;

export default capitalSlice.reducer;
