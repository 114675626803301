import { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import { Tooltip } from "@material-ui/core";
import { Edit as EditIcon } from "react-feather";
import { NavLink as RouterLink } from "react-router-dom";
import * as React from "react";
import useAccount from "../../hooks/useAccount";

const DialogChangePass = ({ userId }) => {
  const { changePass } = useAccount();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <RouterLink
        className="mr-1 rounded-full bg-primary text-action-color p-2"
        onClick={handleClickOpen}
      >
        <Tooltip title="Sửa tài khoản" placement="top">
          <EditIcon size={16} />
        </Tooltip>
      </RouterLink>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          pt: 5
        }}
        fullWidth
        maxWidth="sm"
        PaperProps={{
          component: "form",
          onSubmit: async e => {
            e.preventDefault();
            const formData = new FormData(e.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const { password } = formJson;
            const res = await changePass({ password, id: userId });
            if (!res.success) {
              toast.error(res?.error?.message);
            }
            handleClose();
          }
        }}
      >
        <DialogTitle
          sx={{ fontSize: 20, textTransform: "uppercase", color: "white" }}
          className="bg-primary"
        >
          Đổi mật khẩu
        </DialogTitle>
        <DialogContent>
          <TextField
            required
            id="password"
            name="password"
            label="Mật khẩu mới"
            type="password"
            size="small"
            margin="normal"
            fullWidth
            variant="outlined"
          />
        </DialogContent>
        <DialogActions
          sx={{
            borderTop: "1px solid rgba(224, 224, 224, 1)"
          }}
        >
          <Button onClick={handleClose}>Huỷ</Button>
          <Button type="submit">Cập nhật</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

DialogChangePass.propTypes = {
  userId: PropTypes.number.isRequired
};
export default DialogChangePass;
