import axios from "axios";
import { toast } from "react-toastify";
import {
  getTokens,
  NOT_FOUND_RESPONSE,
  SERVER_ERROR_RESPONSE,
  UN_AUTHOR_RESPONSE,
  removeTokens,
  BAD_REQUEST
} from "../utils";

const forceLogout = () => {
  removeTokens();
  window.location.href = "/login";
};

const httpClient = axios.create({
  baseURL: process.env.REACT_APP_API_URI,
  withCredentials: true
});

httpClient.interceptors.request.use(
  config => {
    const token = getTokens().auth;
    if (token) {
      config.headers.common.Authorization = token ? `Bearer ${token}` : "";
    }
    config.headers["GET-ORIGIN"] = window.location.origin;
    config.withCredentials = false;
    return config;
  },
  error => Promise.reject(error)
);

httpClient.interceptors.response.use(
  response => response,
  error => {
    // whatever you want to do with the error
    // throw error;
    const statusCode = error.response ? error.response.status : null;
    if (!statusCode) {
      // network error
      toast.error("Không thể kêt nối với máy chủ", {
        theme: "colored"
      });
      // forceLogout();
    }
    if (statusCode === NOT_FOUND_RESPONSE) {
      // toast("The requested resource does not exist or has been deleted");
      // forceLogout();
      // toast.error("The requested resource does not exist or has been deleted");
    }
    if (statusCode === BAD_REQUEST) {
      // const { message } = error.response.data.error;
      // toast.error(message);
      const response = {
        ...(error.response && error.response.data)
      };
      if (
        response.error.message &&
        Array.isArray(response.error.message) &&
        response.error.message.length > 0
      ) {
        // eslint-disable-next-line prefer-destructuring
        response.error.message = response.error.message[0];
      }
      return response;
    }

    if (statusCode === SERVER_ERROR_RESPONSE) {
      // toast("The requested resource does not exist or has been deleted");
      toast.error("We're sorry but something went wrong");
    }

    // todo check token expired
    if (statusCode === UN_AUTHOR_RESPONSE) {
      // todo if token is expired call api to get refresh token and renew it
      // toast("Please login to access this resource");
      forceLogout();
      // toast.error("Please log in to access this resource");
    }
    return {
      ...(error.response && error.response.data)
    };
  }
);
export default httpClient;
