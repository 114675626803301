import { createTheme, colors } from "@material-ui/core";
import shadows from "./shadows";
import typography from "./typography";

const theme = createTheme({
  palette: {
    background: {
      default: "#F4F6F8",
      paper: colors.common.white
    },
    primary: {
      contrastText: "#ffffff",
      main: "#5664d2"
    },
    notification: {
      main: "#f4516c"
    },
    text: {
      primary: "#172b4d",
      secondary: "#6b778c",
      warning: "#f4516c",
      main: "#172b4d"
    },
    table: {
      hover: "#f4f3f7"
    }
  },
  shadows,
  typography,
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          "& :-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 1000px #ffffff inset"
          },
          "& :-webkit-autofill:focus": {
            WebkitBoxShadow: "0 0 0 1000px #ffffff inset"
          },
          "& :-webkit-autofill:hover": {
            WebkitBoxShadow: "0 0 0 1000px #ffffff inset"
          }
        }
      }
    },
    MuiTableCell: {
      head: {
        backgroundColor: "#f4f3f7 !important"
      }
    },
    MUIDataTableHeadCell: {
      styleOverrides: {
        fixedHeader: { backgroundColor: "#f4f3f7" }
      }
    },
    MUIDataTable: {
      root: {
        backgroundColor: "#AAF"
      },
      paper: {
        boxShadow: "none"
      }
    },
    MUIDataTableBodyCell: {
      root: {
        backgroundColor: "#FFF"
      }
    },
    MuiToolbar: {
      root: {
        backgroundColor: "#f4f3f7"
      }
    },
    // MuiButton: {
    //   styleOverrides: {
    //     root: {
    //       justifyContent: 'center',
    //     },
    //   },
    // },
    MUIDataTableSelectCell: {
      headerCell: {
        backgroundColor: "blue"
      }
    }
  }
});

export default theme;
